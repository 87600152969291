import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { BackSVG } from "../../../App";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CheckEmailAPI } from "../../../services/auth";
import { notificationMessageFunction } from "../../../services/notificationConst";

const inputStyle = {
  width: "100%",
  backgroundColor: "#EDEFF3",
  paddingLeft: "50px",
  border: "0px solid",
  borderRadius: "16px",
  fontSize: "14px",
  height: "55px",
  color: "#1F2937",
  marginLeft: "10px",
};

const buttonStyle = {
  background: "none",
  border: "none",
  outline: "none",
  paddingLeft: "10px",
  paddingRight: "10px",
};

const dropdownStyle = {
  fontSize: "16px",
  fontWeight: "500",
  border: "1px solid #E5E7EB",
  backgroundColor: "#FFFFFF",
  maxHeight: "300px",
  width: "350px",
  overflowY: "auto",
};

const searchStyle = {
  fontSize: "14px",
  padding: "10px",
  border: "1px solid #E5E7EB",
  marginBottom: "8px",
};

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(15, "Name must be less than 15 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone_number: Yup.string()
    .required("Please enter phone number")
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(8, "Phone number must be at least 8 characters"),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters"),
  confirm_password: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

function RegisterPage() {
  let params = window.location.search;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    setIsLoading(true);
    CheckEmailAPI(values)
      .then(() => {
        setIsLoading(false);
        history.push({
          pathname: `/invoice-business-info/${params}`,
          state: values,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage));
      });
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-3" style={{ width: "90%" }}>
            <div
              className="cursor-pointer"
              style={{ width: "20%" }}
              onClick={() => history.push(`/invoice-login/${params}`)}
            >
              {BackSVG}
            </div>{" "}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">Sign Up</span>
            </div>
          </div>
          <div className="mt-2">
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirm_password: "",
                phone_number: "",
              }}
              enableReinitialize
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-1 flex flex-col px-4 pt-2">
                    <label
                      htmlFor="name"
                      className="font-size-14 ml-1 mb-1 font-weight-500"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      value={values.name}
                      placeholder="Enter name"
                      onChange={handleChange}
                      name="name"
                      className={`input-field font-size-14 ${
                        errors.name && touched.name ? "error-border" : ""
                      }`}
                    />
                    {errors.name && touched.name && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.name}
                      </div>
                    )}
                  </div>

                  <div className="mb-1 flex flex-col px-4">
                    <label
                      htmlFor="email"
                      className="font-size-14 ml-1 mb-1 font-weight-500"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      value={values.email}
                      placeholder="Enter email address"
                      onChange={handleChange}
                      name="email"
                      className={`input-field font-size-14 ${
                        errors.email && touched.email ? "error-border" : ""
                      }`}
                    />
                    {errors.email && touched.email && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="mb-1 flex flex-col px-2 pr-6">
                    <label
                      htmlFor="email"
                      className="font-size-14 ml-1 mb-1 px-2 font-weight-500"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"us"}
                      value={values.phone_number}
                      inputStyle={{
                        ...inputStyle,
                        border: `${
                          errors.phone_number && touched.phone_number
                            ? "1px solid red"
                            : "0px"
                        }`,
                      }}
                      onChange={(val) => {
                        if (val) {
                          setFieldValue("phone_number", val);
                        } else {
                          setFieldValue("phone_number", "");
                        }
                      }}
                      buttonStyle={buttonStyle}
                      dropdownClass="font-size-16"
                      dropdownStyle={dropdownStyle}
                      specialLabel=""
                      searchStyle={searchStyle}
                    />

                    {errors.phone_number && touched.phone_number && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.phone_number}
                      </div>
                    )}
                  </div>

                  <div className="mb-1 flex flex-col px-4">
                    <label
                      htmlFor="email"
                      className="font-size-14 ml-1 mb-1 font-weight-500"
                    >
                      Password
                    </label>{" "}
                    <input
                      type="password"
                      value={values.password}
                      placeholder="Enter password"
                      onChange={handleChange}
                      name="password"
                      className={`input-field font-size-14 ${
                        errors.password && touched.password
                          ? "error-border"
                          : ""
                      }`}
                    />
                    {errors.password && touched.password && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  <div className="mb-1 flex flex-col px-4">
                    <label
                      htmlFor="email"
                      className="font-size-14 ml-1 mb-1 font-weight-500"
                    >
                      Confirm Password
                    </label>{" "}
                    <input
                      type="password"
                      value={values.confirm_password}
                      placeholder="Enter confirm password"
                      onChange={handleChange}
                      name="confirm_password"
                      className={`input-field font-size-14 ${
                        errors.confirm_password && touched.confirm_password
                          ? "error-border"
                          : ""
                      }`}
                    />
                    {errors.confirm_password && touched.confirm_password && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.confirm_password}
                      </div>
                    )}
                  </div>
                  <div
                    className="flex justify-center p-4 button-div"
                    style={{ position: "absolute", bottom: "5px" }}
                  >
                    <button
                      id="login-button"
                      type="submit"
                      className="button-style"
                    >
                      <div className="font-size-14">Continue</div>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default RegisterPage;
