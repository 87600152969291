export const widgetParams = {
  INVOICE_CODE: "invoice_code",
  API_KEY: "api_key",
  TOKEN: "token",
  AMOUNT: "amount",
  WALLET: "wallet",
  IS_DEV: "is_dev",
  NAME: "name",
  CUSTOM_ID: "custom_id",
  LANG: "lang",
  CLICKID: "clickID",
  CAMPAIGN_ID: "campaignID",
  UTM_SOURCE: "utm_source",
  STATE: "state",
  DATE_OF_BIRTH: "date_of_birth",
  COUNTRY: "country",
  POSTAL_CODE: "postal_code",
  EMAIL: "email",
  PHONE_NUMBER: "phone",
  FULL_NAME: "full_name",
  INVOICE_ID: "invoice_id",
};

export const createParamsData = (paramsURL) => {
  let paramsValues = {};
  for (const [key, value] of Object.entries(widgetParams)) {
    if (new URLSearchParams(paramsURL).get([value])) {
      paramsValues = {
        ...paramsValues,
        [value]: new URLSearchParams(paramsURL).get([value]),
      };
    }
  }

  return paramsValues;
};
