import React, { useEffect, useState } from "react";
import { BackSVG } from "../../App";
import { CreateInvoiceAPI, GetMyProfileAPI } from "../../services/invoice";
import { useHistory } from "react-router-dom";
import "./login.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import InvoiceLinkDrawer from "./InvoiceLinkDrawer";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../services/notificationConst";
import { GetWindBeneficiaryAPI } from "../../services/auth";
import { RiFileCopyLine } from "react-icons/ri";
import copy from "copy-to-clipboard";

function SimpleInvoice() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [beneficiaryData, setBeneficiaryData] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [userData, setUserData] = useState({});

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        setUserData(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getBeneficiary = () => {
    setIsLoading(true);
    GetWindBeneficiaryAPI()
      .then((res) => {
        setIsLoading(false);
        setBeneficiaryData(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getBeneficiary();
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Product/Service title is required"),
    amount: Yup.number()
      .min(50, "Amount should be at least 50")
      .required("Amount is required")
      .positive("Amount must be greater than zero"),
  });

  const handleSubmit = (values) => {
    let data = {
      ...values,
      final_amount: values.amount,
      final_amount_card: values.amount,
      create_and_deploy: true,
      pay_fee: "customer_pay",
      receive_in: values.receive_in,
    };
    setIsLoading(true);
    setIsCreating(true);
    CreateInvoiceAPI(data)
      .then((res) => {
        setIsLoading(false);
        setOpenLinkModal(true);
        setIsCreating(false);
        setInvoiceData(res.data.data.data);
        toast.success("Invoice created successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-col justify-center align-items-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
          {isCreating && <label>Creating your invoice link</label>}
        </div>
      ) : (
        <div className="invoice-container">
          <div
            className="p-4 cursor-pointer"
            onClick={() => history.push(`/invoice-widget`)}
            style={{ width: "20%" }}
          >
            {BackSVG}
          </div>
          <span className="text-left font-size-32 page-title mt-4 pt-4 px-4">
            New Payment Link
          </span>
          <div className="form-container">
            <Formik
              initialValues={{
                title: "",
                amount: "",
                receive_in: "crypto",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-1 flex flex-col px-4 pt-4">
                    <label
                      htmlFor="title"
                      className="font-size-16 font-weight-500 ml-1 mb-1"
                    >
                      Service / Product
                    </label>
                    <input
                      type="text"
                      value={values.title}
                      placeholder="Enter product/service"
                      onChange={handleChange}
                      name="title"
                      className={`input-field font-size-14 ${
                        errors.title && touched.title ? "error-border" : ""
                      }`}
                    />
                    {errors.title && touched.title && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.title}
                      </div>
                    )}
                  </div>

                  <div className="mb-3 flex flex-col px-4 pt-1">
                    <label htmlFor="title" className="font-size-16 ml-1 mb-1">
                      Amount ($)
                    </label>
                    <input
                      type="number"
                      value={values.amount}
                      placeholder="Enter amount"
                      onChange={handleChange}
                      name="amount"
                      className={`input-field pr-3 font-size-14 ${
                        errors.amount && touched.amount ? "error-border" : ""
                      }`}
                    />
                    {errors.amount && touched.amount && (
                      <div className="text-left mt-1 ml-2 text-danger font-size-14">
                        {errors.amount}
                      </div>
                    )}
                  </div>

                  {beneficiaryData &&
                    Object.keys(beneficiaryData).length > 0 && (
                      <>
                        <div className="mb-3 flex flex-col px-4 pt-1">
                          <label
                            htmlFor="title"
                            className="font-size-16 ml-1 mb-1"
                          >
                            Receive In
                          </label>
                          <div className="flex flex-row">
                            <div className="flex align-items-center ml-1">
                              <input
                                type="radio"
                                name="receive_in"
                                className="mt-2 mr-2 cursor-pointer"
                                value={"crypto"}
                                checked={values.receive_in === "crypto"}
                                onChange={() => {
                                  setFieldValue("receive_in", "crypto");
                                }}
                              />{" "}
                              <label className="font-size-14 mt-2">
                                Crypto
                              </label>
                            </div>
                            <div className="flex align-items-center ml-4">
                              <input
                                type="radio"
                                name="receive_in"
                                className="mt-2 mr-2 cursor-pointer"
                                value={"fiat"}
                                checked={values.receive_in === "fiat"}
                                onChange={() => {
                                  setFieldValue("receive_in", "fiat");
                                }}
                              />{" "}
                              <label className="font-size-14 mt-2">Fiat</label>
                            </div>
                          </div>
                          {errors.receive_in && touched.receive_in && (
                            <div className="text-left mt-1 ml-2 text-danger font-size-14">
                              {errors.receive_in}
                            </div>
                          )}
                        </div>
                        <div className="mx-4">
                          {values.receive_in === "crypto" ? (
                            <div className="">
                              <span className="font-size-16">
                                Deposit Wallet
                              </span>
                              <div className="mb-1 flex flex-row items-center">
                                <input
                                  type="text"
                                  value={userData.invoice_deposit_wallet}
                                  placeholder="Enter email address"
                                  name="email"
                                  className={`input-field font-size-14 flex-1`}
                                  style={{ paddingRight: "36px" }}
                                  disabled
                                />
                                <div
                                  onClick={() => {
                                    copy(userData.invoice_deposit_wallet);
                                    toast.success(
                                      "Wallet address copied successfully",
                                      {
                                        style: { fontSize: "12px" },
                                      }
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: "25px",
                                    fontSize: "24px",
                                  }}
                                >
                                  <RiFileCopyLine className="ml-2 cursor-pointer" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="p-4 bg-white/90 mt-3 shadow-lg rounded-lg hover:shadow-2xl transition transform hover:-translate-y-1 duration-300"
                              style={{ background: "#F9F9F9" }}
                            >
                              <span className="font-size-16">Bank Details</span>
                              <div className="flex justify-between items-center mb-3">
                                {beneficiaryData &&
                                  beneficiaryData.country &&
                                  beneficiaryData.country.length > 0 &&
                                  beneficiaryData.country[0].flagUrl && (
                                    <img
                                      src={beneficiaryData.country[0].flagUrl}
                                      alt="Country Flag"
                                      className="w-8 h-8 rounded-full"
                                    />
                                  )}
                              </div>

                              <div className="grid grid-cols-2 gap-4 text-sm">
                                <div>
                                  <span className="font-semibold text-gray-700">
                                    Full Name:
                                  </span>
                                  <span className="ml-2 text-gray-600">
                                    {
                                      beneficiaryData.beneficiary
                                        .ReceiverFirstName
                                    }{" "}
                                    {
                                      beneficiaryData.beneficiary
                                        .ReceiverLastName
                                    }
                                  </span>
                                </div>
                                <div>
                                  <span className="font-semibold text-gray-700">
                                    Ac. No.:
                                  </span>
                                  <span className="ml-2 text-gray-600">
                                    {beneficiaryData.BankAccountNumber.slice(
                                      0,
                                      4
                                    )}{" "}
                                    XXXX{" "}
                                    {beneficiaryData.BankAccountNumber.slice(
                                      -4
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="text-sm mt-2">
                                <span className="font-semibold text-gray-700">
                                  Bank Name:
                                </span>
                                <span className="ml-2 text-gray-600">
                                  {beneficiaryData.BankName}
                                </span>
                              </div>
                              <div className="text-sm mt-2">
                                <span className="font-semibold text-gray-700">
                                  Currency:
                                </span>
                                <span className="ml-2 text-gray-600">
                                  {beneficiaryData.PayoutCurrency}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                  <div
                    className="flex justify-center p-4 button-div"
                    style={{
                      position: "absolute",
                      bottom: "5px",
                    }}
                  >
                    <button
                      id="login-button"
                      type="submit"
                      className="button-style"
                    >
                      <div className="font-size-14">Create Payment Link</div>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Drawer
            open={openLinkModal}
            onClose={() => setOpenLinkModal(false)}
            direction="bottom"
            style={{
              zIndex: 10000,
              height: "80%",
              borderRadius: 10,
              background: "#F7F7F7",
            }}
          >
            <InvoiceLinkDrawer
              invoiceData={invoiceData}
              setOpenLinkModal={setOpenLinkModal}
            />
          </Drawer>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default SimpleInvoice;
