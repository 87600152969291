import React, { useEffect, useState } from "react";
import { GetMyProfileAPI } from "../../services/invoice";
import { BackSVG } from "../../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { RiFileCopyLine } from "react-icons/ri";

function Developers() {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const history = useHistory();

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-3" style={{ width: "90%" }}>
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push(`/invoice-widget`);
              }}
            >
              {BackSVG}
            </div>{" "}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">Developers</span>
            </div>
          </div>
          <div className="p-4">
            <span>Developer Key</span>
            <div className="mb-1 flex flex-row items-center">
              <input
                type="text"
                value={userData.api_key}
                name="email"
                className={`input-field font-size-14 flex-1`}
                style={{ paddingRight: "36px" }}
                disabled
              />
              <div
                onClick={() => {
                  copy(userData.api_key);
                  toast.success("Wallet address copied successfully", {
                    style: { fontSize: "12px" },
                  });
                }}
                style={{
                  position: "absolute",
                  right: "25px",
                  fontSize: "24px",
                }}
              >
                <RiFileCopyLine className="ml-2 cursor-pointer" />
              </div>
            </div>
            <div className="mt-4">
              <a
                href="https://pubdoc.1buy.io/api"
                target="_blank"
                rel="noopener noreferrer"
                className="font-size-14 text-blue cursor-pointer"
                style={{
                  textDecoration: "none",
                  color: "#007bff",
                  fontWeight: "bold",
                }}
              >
                View API Documentation
              </a>
            </div>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default Developers;
