import React, { useEffect, useState } from "react";
import { BackSVG } from "../../../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WindCustomerAPI } from "../../../services/auth";

function KycIframe() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [customerData, setCustomerData] = useState({});

  const getCustomer = () => {
    setIsLoading(true);
    WindCustomerAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;

        setCustomerData(data);
      })
      .catch(() => {
        setIsLoading(false);
        console.error("Error fetching user profile");
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-col justify-center align-items-center"
          style={{ background: "#fff", height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#fff" }}>
          <div className="p-4 d-flex flex-row align-items-center justify-content-between">
            <div
              className="cursor-pointer"
              onClick={() => history.push("/invoice-bank-details")}
            >
              {BackSVG}
            </div>
            <span className="d-flex justify-content-center align-items-center mx-auto font-size-18 font-weight-600">
              KYC
            </span>
          </div>
          <div className="px-2">
            <iframe
              src={`${customerData?.kycUrl}`}
              title="1buy.io"
              className="px-3"
              style={{ width: "95%", height: "91vh" }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default KycIframe;
