import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
import Checkout from "./partials/Checkout";
import Pay from "./partials/InvoicePay/Pay";
import PaymentWidget from "./partials/InvoicePay/PaymentWidget";
import PaymentMethods from "./partials/InvoicePay/PaymentMethods";
import InvoiceWidget from "./partials/Invoice/InvoiceWidget";
import Invoice from "./partials/Invoice";
import LoginPage from "./partials/Invoice/Auth/LoginPage";
import SimpleInvoice from "./partials/Invoice/SimpleInvoice";
import SidebarMenu from "./partials/Invoice/SidebarMenu";
import Flap from "./partials/Flap";
import RegisterPage from "./partials/Invoice/Auth/RegisterPage";
import BusinessInfoPage from "./partials/Invoice/Auth/BusinessInfoPage";
import Verification from "./partials/Invoice/Auth/Verification";
import PayWidget from "./partials/InvoicePay/PayWidget";
import CardPayment from "./partials/InvoicePay/CardPayment";
import Invoices from "./partials/Invoice/Invoices";
import InvoiceDetail from "./partials/Invoice/InvoiceDetail";
import InvoiceWallet from "./partials/Invoice/Auth/InvoiceWallet";
import CryptoPayment from "./partials/InvoicePay/CryptoPayment";
import InvoicePaid from "./partials/Invoice/InvoicePaid";
import BusinessInfoPage1 from "./partials/Invoice/Auth/BusinessInfoPage1";
import BankDetails from "./partials/Invoice/Auth/BankDetails";
import BeneficiaryDetails from "./partials/Invoice/Auth/BeneficiaryDetails";
import BankPayment from "./partials/InvoicePay/BankPayment";
import BankAccounts from "./partials/Invoice/Auth/BankAccounts";
import Property from "./partials/Property";
import CryptoJS from "crypto-js";
import SidebarDrawer from "./partials/Invoice/SidebarDrawer";
import FullInvoice from "./partials/Invoice/FullInvoice/FullInvoice";
import RedirectTest from "./partials/RedirectTest";
import BankInvoicePaid from "./partials/Invoice/BankInvoicePaid";
import PersonalInfo from "./partials/Invoice/Auth/PersonalInfo";
import Developers from "./partials/Invoice/Developers";
import KycIframe from "./partials/Invoice/KYC/KycIframe";

export function decryptText(text) {
  if (!process.env.REACT_APP_ENCRYPTION_KEY || !process.env.REACT_APP_IV_KEY) {
    console.error("Encryption/IV keys are not set");
    return "";
  }

  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

  if (text === null || typeof text === "undefined") return text;

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(text) },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptText(text) {
  if (!process.env.REACT_APP_ENCRYPTION_KEY || !process.env.REACT_APP_IV_KEY) {
    console.error("Encryption key or IV key is not set.");
    return null;
  }
  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);

  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

  try {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
}

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]);

  return (
    <>
      <Switch>
        <Route exact path="/redirect-test/:hash">
          <RedirectTest />
        </Route>
        <Route exact path="/properties">
          <Property />
        </Route>
        <Route exact path="/:lang/properties">
          <Property />
        </Route>
        <Route exact path="/airdrops">
          <Home view="airdrops,swap,buy,sell" />
        </Route>
        <Route exact path="/payment-link">
          <PaymentWidget />
        </Route>
        <Route exact path="/pay">
          <SidebarMenu />
          <InvoiceWidget />
        </Route>
        <Route exact path="/invoice/:invoice_code">
          <Pay />
        </Route>
        <Route exact path="/pay/:invoice_code">
          <PayWidget />
        </Route>
        <Route exact path="/pay-widget/:invoice_code">
          <PayWidget />
        </Route>
        <Route exact path="/payment-method/:invoice_code">
          <PaymentMethods />
        </Route>
        <Route exact path="/card-payment/:invoice_code">
          <CardPayment />
        </Route>
        <Route exact path="/crypto-payment/:invoice_code">
          <CryptoPayment />
        </Route>
        <Route exact path="/bank-payment/:invoice_code">
          <BankPayment />
        </Route>

        <Route exact path="/blueprint/flap">
          <Flap />
        </Route>

        {/* // ? Authentication  */}
        <Route path="/invoice-login">
          <LoginPage />
        </Route>
        <Route path="/invoice-register">
          <RegisterPage />
        </Route>
        <Route path="/invoice-personal-info">
          <PersonalInfo />
        </Route>
        <Route path="/invoice-business-info">
          <BusinessInfoPage />
        </Route>
        <Route path="/invoice-business-info1">
          <BusinessInfoPage1 />
        </Route>
        <Route path="/invoice-bank-details">
          <BankDetails />
        </Route>
        <Route path="/invoice-bank-accounts">
          <BankAccounts />
        </Route>
        <Route path="/invoice-beneficiary-details">
          <BeneficiaryDetails />
        </Route>
        <Route path="/invoice-verification">
          <Verification />
        </Route>
        <Route path="/invoice-wallet">
          <InvoiceWallet />
        </Route>
        <Route path="/developers">
          <Developers />
        </Route>

        {/* <Route path="/invoice-forget-password">
          <ForgetPassword />
        </Route>
        <Route path="/invoice-reset-password">
          <ResetPassword />
        </Route>
        <Route path="/invoice-success-password">
          <PasswordSuccess />
        </Route> */}
        <Route exact path="/settings">
          <SidebarDrawer />
        </Route>
        <Route exact path="/invoice">
          <Invoice />
        </Route>
        <Route path="/crypto-paid/:invoice_code">
          <InvoicePaid />
        </Route>
        <Route path="/bank-paid/:invoice_code">
          <BankInvoicePaid />
        </Route>
        <Route exact path="/invoice-widget">
          <SidebarMenu />
          <InvoiceWidget />
        </Route>

        <Route exact path="/kyc">
          <KycIframe />
        </Route>

        <Route exact path="/simple-invoice">
          <SimpleInvoice />
        </Route>

        <Route exact path="/full-invoice">
          <FullInvoice />
        </Route>
        <Route exact path="/invoices">
          <SidebarMenu />
          <Invoices />
        </Route>
        <Route exact path="/invoice-detail">
          <SidebarMenu />
          <InvoiceDetail />
        </Route>

        <Route path="/:param/:referrals">
          <Home view="swap,buy,sell,airdrops" />
        </Route>
        <Route exact path="/checkout">
          <Checkout />
        </Route>
        <Route path="/:referrals">
          <Home view="swap,buy,sell,airdrops" />
        </Route>
        <Route exact path="/">
          <Home view="swap,buy,sell,airdrops" />
        </Route>
      </Switch>
    </>
  );
}

export default App;

export const BackSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z"
      fill="black"
    />
    <path
      d="M5.56038 12L11.7809 18.219C11.9217 18.3598 12.0008 18.5508 12.0008 18.75C12.0008 18.9492 11.9217 19.1402 11.7809 19.281C11.64 19.4218 11.449 19.5009 11.2499 19.5009C11.0507 19.5009 10.8597 19.4218 10.7189 19.281L3.96888 12.531C3.89903 12.4613 3.84362 12.3786 3.80581 12.2875C3.768 12.1963 3.74854 12.0987 3.74854 12C3.74854 11.9014 3.768 11.8037 3.80581 11.7126C3.84362 11.6214 3.89903 11.5387 3.96888 11.469L10.7189 4.719C10.8597 4.57817 11.0507 4.49905 11.2499 4.49905C11.449 4.49905 11.64 4.57817 11.7809 4.719C11.9217 4.85983 12.0008 5.05084 12.0008 5.25C12.0008 5.44916 11.9217 5.64017 11.7809 5.781L5.56038 12Z"
      fill="black"
    />
  </svg>
);

export const CloseSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M13.3002 0.709995C13.2077 0.617291 13.0978 0.543744 12.9768 0.493562C12.8559 0.443381 12.7262 0.417551 12.5952 0.417551C12.4643 0.417551 12.3346 0.443381 12.2136 0.493562C12.0926 0.543744 11.9827 0.617291 11.8902 0.709995L7.00022 5.58999L2.11022 0.699995C2.01764 0.607413 1.90773 0.533973 1.78677 0.483868C1.6658 0.433763 1.53615 0.407974 1.40522 0.407974C1.27429 0.407974 1.14464 0.433763 1.02368 0.483868C0.902716 0.533973 0.792805 0.607413 0.700223 0.699995C0.607642 0.792576 0.534202 0.902487 0.484097 1.02345C0.433992 1.14442 0.408203 1.27406 0.408203 1.40499C0.408203 1.53593 0.433992 1.66557 0.484097 1.78654C0.534202 1.9075 0.607642 2.01741 0.700223 2.10999L5.59022 6.99999L0.700223 11.89C0.607642 11.9826 0.534202 12.0925 0.484097 12.2135C0.433992 12.3344 0.408203 12.4641 0.408203 12.595C0.408203 12.7259 0.433992 12.8556 0.484097 12.9765C0.534202 13.0975 0.607642 13.2074 0.700223 13.3C0.792805 13.3926 0.902716 13.466 1.02368 13.5161C1.14464 13.5662 1.27429 13.592 1.40522 13.592C1.53615 13.592 1.6658 13.5662 1.78677 13.5161C1.90773 13.466 2.01764 13.3926 2.11022 13.3L7.00022 8.40999L11.8902 13.3C11.9828 13.3926 12.0927 13.466 12.2137 13.5161C12.3346 13.5662 12.4643 13.592 12.5952 13.592C12.7262 13.592 12.8558 13.5662 12.9768 13.5161C13.0977 13.466 13.2076 13.3926 13.3002 13.3C13.3928 13.2074 13.4662 13.0975 13.5163 12.9765C13.5665 12.8556 13.5922 12.7259 13.5922 12.595C13.5922 12.4641 13.5665 12.3344 13.5163 12.2135C13.4662 12.0925 13.3928 11.9826 13.3002 11.89L8.41022 6.99999L13.3002 2.10999C13.6802 1.72999 13.6802 1.08999 13.3002 0.709995Z"
      fill="black"
    />
  </svg>
);

export const MailSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M22 7.535V17C22 17.7652 21.7077 18.5015 21.1827 19.0583C20.6578 19.615 19.9399 19.9501 19.176 19.995L19 20H5C4.23479 20 3.49849 19.7077 2.94174 19.1827C2.38499 18.6578 2.04989 17.9399 2.005 17.176L2 17V7.535L11.445 13.832L11.561 13.898C11.6977 13.9648 11.8478 13.9995 12 13.9995C12.1522 13.9995 12.3023 13.9648 12.439 13.898L12.555 13.832L22 7.535Z"
      fill="black"
    />
    <path
      d="M18.9998 4C20.0798 4 21.0268 4.57 21.5548 5.427L11.9998 11.797L2.44482 5.427C2.69562 5.01982 3.04011 4.6785 3.4496 4.43149C3.85908 4.18448 4.32168 4.03894 4.79882 4.007L4.99982 4H18.9998Z"
      fill="black"
    />
  </svg>
);

export const ShareSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
  >
    <path
      d="M1 11V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V11M13 5L9 1M9 1L5 5M9 1V14"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const QRCodeSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 11H9C10.1 11 11 10.1 11 9V5C11 3.9 10.1 3 9 3H5C3.9 3 3 3.9 3 5V9C3 10.1 3.9 11 5 11ZM5 5H9V9H5V5ZM5 21H9C10.1 21 11 20.1 11 19V15C11 13.9 10.1 13 9 13H5C3.9 13 3 13.9 3 15V19C3 20.1 3.9 21 5 21ZM5 15H9V19H5V15ZM13 5V9C13 10.1 13.9 11 15 11H19C20.1 11 21 10.1 21 9V5C21 3.9 20.1 3 19 3H15C13.9 3 13 3.9 13 5ZM19 9H15V5H19V9ZM21 20.5V19.5C21 19.22 20.78 19 20.5 19H19.5C19.22 19 19 19.22 19 19.5V20.5C19 20.78 19.22 21 19.5 21H20.5C20.78 21 21 20.78 21 20.5ZM13 13.5V14.5C13 14.78 13.22 15 13.5 15H14.5C14.78 15 15 14.78 15 14.5V13.5C15 13.22 14.78 13 14.5 13H13.5C13.22 13 13 13.22 13 13.5ZM16.5 15H15.5C15.22 15 15 15.22 15 15.5V16.5C15 16.78 15.22 17 15.5 17H16.5C16.78 17 17 16.78 17 16.5V15.5C17 15.22 16.78 15 16.5 15ZM13 17.5V18.5C13 18.78 13.22 19 13.5 19H14.5C14.78 19 15 18.78 15 18.5V17.5C15 17.22 14.78 17 14.5 17H13.5C13.22 17 13 17.22 13 17.5ZM15.5 21H16.5C16.78 21 17 20.78 17 20.5V19.5C17 19.22 16.78 19 16.5 19H15.5C15.22 19 15 19.22 15 19.5V20.5C15 20.78 15.22 21 15.5 21ZM17.5 19H18.5C18.78 19 19 18.78 19 18.5V17.5C19 17.22 18.78 17 18.5 17H17.5C17.22 17 17 17.22 17 17.5V18.5C17 18.78 17.22 19 17.5 19ZM18.5 13H17.5C17.22 13 17 13.22 17 13.5V14.5C17 14.78 17.22 15 17.5 15H18.5C18.78 15 19 14.78 19 14.5V13.5C19 13.22 18.78 13 18.5 13ZM19.5 17H20.5C20.78 17 21 16.78 21 16.5V15.5C21 15.22 20.78 15 20.5 15H19.5C19.22 15 19 15.22 19 15.5V16.5C19 16.78 19.22 17 19.5 17Z"
      fill="black"
    />
  </svg>
);

export const CopySVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1.25H10.944C9.106 1.25 7.65 1.25 6.511 1.403C5.339 1.561 4.39 1.893 3.641 2.641C2.893 3.39 2.561 4.339 2.403 5.511C2.25 6.651 2.25 8.106 2.25 9.944V16C2.24982 16.8935 2.56866 17.7577 3.1491 18.4369C3.72954 19.1162 4.53343 19.5659 5.416 19.705C5.553 20.469 5.818 21.121 6.348 21.652C6.95 22.254 7.708 22.512 8.608 22.634C9.475 22.75 10.578 22.75 11.945 22.75H15.055C16.422 22.75 17.525 22.75 18.392 22.634C19.292 22.512 20.05 22.254 20.652 21.652C21.254 21.05 21.512 20.292 21.634 19.392C21.75 18.525 21.75 17.422 21.75 16.055V10.945C21.75 9.578 21.75 8.475 21.634 7.608C21.512 6.708 21.254 5.95 20.652 5.348C20.121 4.818 19.469 4.553 18.705 4.416C18.5659 3.53343 18.1162 2.72954 17.4369 2.1491C16.7577 1.56866 15.8935 1.24982 15 1.25ZM17.13 4.271C16.9779 3.827 16.6909 3.44166 16.3089 3.16893C15.927 2.89619 15.4693 2.74971 15 2.75H11C9.093 2.75 7.739 2.752 6.71 2.89C5.705 3.025 5.125 3.279 4.702 3.702C4.279 4.125 4.025 4.705 3.89 5.71C3.752 6.739 3.75 8.093 3.75 10V16C3.74971 16.4693 3.89619 16.927 4.16892 17.3089C4.44166 17.6908 4.827 17.9779 5.271 18.13C5.25 17.52 5.25 16.83 5.25 16.055V10.945C5.25 9.578 5.25 8.475 5.367 7.608C5.487 6.708 5.747 5.95 6.348 5.348C6.95 4.746 7.708 4.488 8.608 4.367C9.475 4.25 10.578 4.25 11.945 4.25H15.055C15.83 4.25 16.52 4.25 17.13 4.271ZM7.408 6.41C7.685 6.133 8.073 5.953 8.808 5.854C9.562 5.753 10.564 5.751 11.999 5.751H14.999C16.434 5.751 17.435 5.753 18.191 5.854C18.925 5.953 19.313 6.134 19.59 6.41C19.867 6.687 20.047 7.075 20.146 7.81C20.247 8.564 20.249 9.566 20.249 11.001V16.001C20.249 17.436 20.247 18.437 20.146 19.193C20.047 19.927 19.866 20.315 19.59 20.592C19.313 20.869 18.925 21.049 18.19 21.148C17.435 21.249 16.434 21.251 14.999 21.251H11.999C10.564 21.251 9.562 21.249 8.807 21.148C8.073 21.049 7.685 20.868 7.408 20.592C7.131 20.315 6.951 19.927 6.852 19.192C6.751 18.437 6.749 17.436 6.749 16.001V11.001C6.749 9.566 6.751 8.564 6.852 7.809C6.951 7.075 7.132 6.687 7.408 6.41Z"
      fill="black"
    />
  </svg>
);

export const BurgerIconSVG = (
  <svg
    data-v-cfd136c2=""
    data-v-78538707=""
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa-id="icon-burger-menu"
    className="icon"
  >
    <path
      data-v-cfd136c2=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm16 5H4v2h16v-2z"
      fill="currentColor"
    ></path>
  </svg>
);

export const SuccessSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="146"
    height="146"
    viewBox="0 0 146 146"
    fill="none"
  >
    <path
      d="M2 73.25C2 33.9042 33.9042 2 73.25 2C112.596 2 144.5 33.9042 144.5 73.25C144.5 112.596 112.596 144.5 73.25 144.5C33.9042 144.5 2 112.596 2 73.25Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.5835 73.25L65.3335 97L104.917 57.4166"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SentSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.38889 0C1.11275 0 0.888887 0.223858 0.888887 0.5C0.888887 0.776142 1.11275 1 1.38889 1H7.24237L0.156689 7.69203C-0.0440695 7.88164 -0.0531112 8.19809 0.136495 8.39885C0.3261 8.59961 0.642554 8.60865 0.843313 8.41904L7.99998 1.65997L7.99998 7.61109C7.99998 7.88723 8.22384 8.11109 8.49998 8.11109C8.77612 8.11109 8.99998 7.88723 8.99998 7.61109V1C8.99998 0.447716 8.55227 0 7.99998 0H1.38889Z"
      fill="black"
    />
  </svg>
);

export const ReceiveSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61111 9C7.88725 9 8.11111 8.77614 8.11111 8.5C8.11111 8.22386 7.88725 8 7.61111 8H1.75763L8.84331 1.30797C9.04407 1.11836 9.05311 0.801911 8.86351 0.601152C8.6739 0.400393 8.35745 0.391352 8.15669 0.580957L1.00002 7.34003L1.00002 1.38891C1.00002 1.11277 0.776163 0.888908 0.50002 0.888908C0.223877 0.888908 2.00272e-05 1.11277 2.00272e-05 1.38891V8C2.00272e-05 8.55228 0.447734 9 1.00002 9H7.61111Z"
      fill="black"
    />
  </svg>
);
