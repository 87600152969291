import React from "react";

function HeroHome({
  view,
  defaultCrypto = "",
  funMode,
  fromToken,
  toToken,
  toNetwork,
  fromNetwork,
  referrals = "",
}) {
  return (
    <div className="pt-32 pb-12 md:pt-24">
      <div
        className="text-center flex justify-center"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <div className="row-start-1 row-span-2 rounded-lg text-3xl mb-6">
          <span className="font-size-24 font-weight-500">
            1 place for all crypto{" "}
          </span>
        </div>

        <div
          className={`card-new-2 mb-3 mt-3 `}
          style={{
            boxShadow: `0.4rem 0.4rem black`,
            border: `0.6vmin solid black`,
          }}
        >
          <iframe
            src={`https://widget.rampnalysis.com/buy-sell/?widgetReferral=${referrals}&toNetwork=${toNetwork}&fromNetwork=${fromNetwork}&fromToken=${fromToken}&toToken=${toToken}&funMode=${funMode}&view=${view}&defaultCrypto=${defaultCrypto}&accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFwaV9rZXkiOiIwZTRmOTU2Zi1jN2U1LTQxNzEtOGU3Zi03YzM4OThlZTg3OWUiLCJzZWNyZXRfa2V5IjoiZjVkYzlhMzc4NWNmODhkYWM1NGQwN2E0ZTQ0YTIyMDcxNDI5NzY5N2IxMWRlOTA3NWFjNWY4YjQxN2I3ZTIyN2U0YWMyMDk0In0sImlhdCI6MTY5MDQ0ODUxNX0.u2e3rjjGqYoHw1U5P9FlP-TCfqvlckChPMXMBrd_W8Y`}
            style={{
              borderRadius: "10px",
              width: "100%",
              // height: "90vh",
            }}
            title="1buy.io"
            className="widget-iframe"
            frameBorder="1"
          />
        </div>
      </div>
    </div>
  );
}

export default HeroHome;
