import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackSVG } from "../../App";
import { Toaster } from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function SidebarDrawer() {
  let params = window.location.search;
  const history = useHistory();

  return (
    <div style={{ background: "#F7F7F7" }}>
      <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
        <div
          className="cursor-pointer"
          onClick={() => {
            history.push(`/invoice-widget`);
          }}
        >
          {BackSVG}
        </div>{" "}
        <div
          className="flex align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <span className="font-size-18 font-weight-600">Menu</span>
        </div>
      </div>
      <PerfectScrollbar
        style={{
          maxHeight: "90vh",
          overflowX: "hidden",
        }}
      >
        <div className="mr-3 ml-3 py-3">
          <Link to={`/invoice-personal-info/${params}`}>
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Personal Information
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  Manage your personal details
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="mr-3 ml-3 py-3">
          <Link to={`/invoice-wallet/${params}`}>
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Connect Wallet
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  Link your crypto wallet
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="mr-3 ml-3 py-3">
          <Link
            to={{
              pathname: `/invoice-business-info1/${params}`,
              state: { isFromSidebar: true },
            }}
          >
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Business Details
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  Update your business information
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="mr-3 ml-3 py-3">
          <Link to={`/invoices/${params}`}>
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Invoices
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  View and manage your invoices
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="mr-3 ml-3 py-3">
          <Link to={`/invoice-bank-accounts/${params}`}>
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Bank Accounts
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  Manage your linked bank accounts
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="mr-3 ml-3 py-3">
          <Link to={`/developers/${params}`}>
            <div className="flex align-items-center justify-content-between cursor-pointer">
              <div className="d-flex flex-col">
                <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                  Developers
                </label>
                <span className="font-size-12 ml-2 text-muted">
                  Get your API key
                </span>
              </div>
              <div>
                <AiOutlineRight />
              </div>
            </div>
          </Link>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="py-3" style={{ background: "#D3D3D3" }}>
          <div className="mr-3 ml-3 flex align-items-center justify-content-between cursor-pointer">
            <div className="d-flex flex-col">
              <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                Saved Products
              </label>
              <span className="font-size-12 ml-2 text-muted">Coming soon</span>
            </div>
            <div>
              <AiOutlineRight />
            </div>
          </div>
        </div>
        <hr className="mr-3 ml-3" />
        <div className="py-3" style={{ background: "#D3D3D3" }}>
          <div className="mr-3 ml-3 flex align-items-center justify-content-between cursor-pointer">
            <div className="d-flex flex-col">
              <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                Connect Accounting Software
              </label>
              <span className="font-size-12 ml-2 text-muted">Coming soon</span>
            </div>
            <div>
              <AiOutlineRight />
            </div>
          </div>
        </div>
        <hr className="mr-3 ml-3" />
        <div
          className="mr-3 ml-3 py-3"
          onClick={() => {
            localStorage.clear();
            history.push("/invoice-login");
          }}
        >
          <div className="flex align-items-center justify-content-between cursor-pointer">
            <div className="d-flex flex-col">
              <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
                Logout
              </label>
              <span className="font-size-12 ml-2 text-muted">
                Sign out of your account
              </span>
            </div>
            <div>
              <AiOutlineRight />
            </div>
          </div>
        </div>
        <hr className="mr-3 ml-3" />
      </PerfectScrollbar>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default SidebarDrawer;
