import React, { useEffect, useState } from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";

import Footer from "../partials/Footer";
import InvestedBy from "../partials/InvestedBy";
import CalendyIframe from "../partials/CalendyIframe";
import CoinsMarquee from "../partials/CoinsMarquee";
import { useParams } from "react-router-dom";
import axios from "axios";

function Home({ view }) {
  let { param, referrals } = useParams();
  const [defaultCrypto, setDefaultCrypto] = useState("");
  const [funMode, setFunMode] = useState(true);
  const [fromToken, setFromToken] = useState("");
  const [referral, setReferral] = useState(null);
  const [toToken, setToToken] = useState("");
  const [fromNetwork, setFromNetwork] = useState("");
  const [toNetwork, setToNetwork] = useState("");

  const getSwingTokens = (
    sendToken = "",
    receiveToken = "",
    fromNetwork = "",
    toNetwork = ""
  ) => {
    axios
      .get("https://apiv2.rampnalysis.com/api/crypto_assets/get_swing_tokens")
      .then((res) => {
        const data = res.data.data.data;
        const from = data.find(
          (coin) => coin.symbol.toLowerCase() === sendToken
        );
        const to = data.find(
          (coin) => coin.symbol.toLowerCase() === receiveToken
        );

        if (!fromNetwork && toNetwork) {
          fromNetwork = toNetwork;
        }
        if (from && Object.keys(from).length > 0) {
          setFromToken(from.symbol);
        }
        if (to && Object.keys(to).length > 0) {
          setToToken(to.symbol);
        }

        let fromNetworkDetail = {};
        let toNetworkDetail = {};
        if (from && to) {
          if (fromNetwork) {
            fromNetworkDetail = from.networks.find(
              (chain) => chain.slug === fromNetwork
            );

            toNetworkDetail = to.networks.find(
              (chain) => chain.slug === fromNetwork
            );
          } else {
            fromNetworkDetail = from.networks.find(
              (chain) =>
                chain.slug &&
                (chain.slug.toUpperCase().includes(from.symbol.toUpperCase()) ||
                  (chain.nativeToken &&
                    chain.nativeToken.symbol.toUpperCase() ===
                      from.symbol.toUpperCase()))
            );
            if (fromNetworkDetail) {
              toNetworkDetail = fromNetworkDetail;
            }
          }

          if (toNetwork) {
            toNetworkDetail = to.networks.find(
              (chain) => chain.slug === toNetwork
            );
          }

          if (fromNetworkDetail && Object.keys(fromNetworkDetail).length > 0) {
            setFromNetwork(fromNetworkDetail.slug);
          }

          if (toNetworkDetail && Object.keys(toNetworkDetail).length > 0) {
            setToNetwork(toNetworkDetail.slug);
          }
        } else if (!from && to) {
          if (fromNetwork) {
            toNetworkDetail = to.networks.find(
              (chain) => chain.slug === fromNetwork
            );
          } else {
            toNetworkDetail = to.networks.find(
              (chain) =>
                chain.slug &&
                (chain.slug.toUpperCase().includes(to.symbol.toUpperCase()) ||
                  (chain.nativeToken &&
                    chain.nativeToken.symbol.toUpperCase() ===
                      to.symbol.toUpperCase()))
            );
          }
          if (toNetworkDetail && Object.keys(toNetworkDetail).length > 0) {
            setToNetwork(toNetworkDetail.slug);
            setFromNetwork(toNetworkDetail.slug);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (param || referrals) {
      let paramValue = param;
      if (referrals && !param) {
        if (referrals.includes(".buy")) {
          setReferral(referrals.replace(".buy", ""));
        } else {
          paramValue = referrals;
        }
      } else {
        if (referrals) {
          setReferral(referrals.replace(".buy", ""));
        }
      }
      if (
        paramValue &&
        paramValue.split("-") &&
        paramValue.split("-").length > 1 &&
        paramValue.split("_") &&
        paramValue.split("_").length > 1 &&
        paramValue.includes("_")
      ) {
        const fromData = paramValue.split("-")[0];
        const toData = paramValue.split("-")[1];
        let fromNet = "";
        let toNet = "";
        let sendToken = "";
        let receiveToken = "";

        if (toData && toData.split("_") && toData.split("_").length > 0) {
          receiveToken = toData.split("_")[0];
          toNet = toData.split("_")[1];
        }

        if (fromData && fromData.split("_") && fromData.split("_").length > 0) {
          sendToken = fromData.split("_")[0];
          fromNet = fromData.split("_")[1] ? fromData.split("_")[1] : toNet;
        }

        getSwingTokens(sendToken, receiveToken, fromNet, toNet);
      } else if (
        paramValue &&
        paramValue.split("-") &&
        paramValue.split("-").length === 2
      ) {
        const sendToken = paramValue.split("-")[0];
        const receiveToken = paramValue.split("-")[1];
        getSwingTokens(sendToken, receiveToken);
      } else if (
        paramValue &&
        paramValue.split("-") &&
        paramValue.split("-").length === 3
      ) {
        const sendToken = paramValue.split("-")[0];
        const receiveToken = paramValue.split("-")[1];
        const network = paramValue.split("-")[2];
        getSwingTokens(sendToken, receiveToken, network, network);
      } else if (
        paramValue &&
        !paramValue.includes("-") &&
        paramValue.includes("_") &&
        paramValue.split("_").length > 1
      ) {
        const receiveToken = paramValue.split("_")[0];
        const network = paramValue.split("_")[1];
        getSwingTokens(
          "",
          receiveToken.toLowerCase(),
          network.toLowerCase(),
          network.toLowerCase()
        );
      } else if (
        paramValue &&
        (!paramValue.split("-") || paramValue.split("-").length === 1) &&
        !paramValue.includes(".buy")
      ) {
        getSwingTokens("", paramValue);
      }
    }
  }, [param, referrals]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header setFunMode={setFunMode} funMode={funMode} />
      <HeroHome
        view={view}
        defaultCrypto={defaultCrypto}
        funMode={funMode}
        fromToken={fromToken}
        toToken={toToken}
        fromNetwork={fromNetwork}
        toNetwork={toNetwork}
        referrals={referral}
      />
      <CoinsMarquee setDefaultCrypto={setDefaultCrypto} />
      <InvestedBy />
      <CalendyIframe />
      <Footer />
    </div>
  );
}

export default Home;
