import React, { useEffect, useState } from "react";
import {
  ActiveWindBeneficiaryAPI,
  GetAllWindBeneficiaryAPI,
} from "../../../services/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackSVG } from "../../../App";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../../services/notificationConst";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function BankAccounts() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const getAllBanks = () => {
    setIsLoading(true);
    GetAllWindBeneficiaryAPI()
      .then((res) => {
        setIsLoading(false);
        setAllBanks(res.data.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message || "An error occurred";
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  const handleRadioChange = (wind_beneficiary_id) => {
    ActiveWindBeneficiaryAPI(wind_beneficiary_id)
      .then((res) => {
        getAllBanks();
        toast.success("Bank changed successfully", {
          style: { fontSize: "12px" },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err?.data?.error?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  return (
    <React.Fragment>
      <div className="bg-gray-100">
        {isLoading ? (
          <div
            className="flex flex-col justify-center align-items-center"
            style={{ height: "100vh", alignItems: "center" }}
          >
            {" "}
            <div className="loader-progress"></div>
          </div>
        ) : (
          <div style={{ background: "#F7F7F7" }} className="pt-4">
            <div className="flex justify-between items-center mb-2 px-4 ">
              <div
                className="cursor-pointer transition"
                onClick={() => history.push("/settings")}
              >
                {BackSVG}
              </div>
              <span className="text-2xl font-size-18 font-semibold">
                Bank Accounts
              </span>
              <div />
            </div>
            <div className="button-group d-flex align-items-end justify-content-right px-4 mb-6">
              <button
                className="font-weight-500 is-active font-size-16 cursor-pointer d-flex align-items-end justify-content-right"
                onClick={() => {
                  history.push("/invoice-bank-details");
                }}
              >
                Add New Account
              </button>
            </div>
            {errorMessage && (
              <div className="text-red-500 text-center py-4 bg-red-100 rounded-lg">
                {errorMessage}
              </div>
            )}

            <PerfectScrollbar style={{ maxHeight: "90vh" }}>
              <div className="px-4 pb-3">
                {allBanks.length > 0 ? (
                  [...allBanks].map((bank) => {
                    const accountNumber = bank.BankAccountNumber;
                    const formattedAccountNumber =
                      accountNumber && accountNumber.length >= 8
                        ? `${accountNumber.slice(
                            0,
                            4
                          )} XXX ${accountNumber.slice(-4)}`
                        : "N/A";

                    return (
                      <div
                        key={bank.wind_beneficiary_id}
                        className="p-4 bg-white/90 mt-3 shadow-lg rounded-lg hover:shadow-2xl transition transform hover:-translate-y-1 duration-300"
                        style={{ background: "#F9F9F9" }}
                      >
                        <div className="flex justify-between items-center mb-3">
                          <div className="flex items-center">
                            <input
                              type="radio"
                              id={`bank-${bank.wind_beneficiary_id}`}
                              name="bankAccount"
                              className="h-5 w-5 text-blue-600 focus:ring-blue-500 cursor-pointer"
                              value={bank.wind_beneficiary_id}
                              onChange={() =>
                                handleRadioChange(bank.wind_beneficiary_id)
                              }
                              checked={bank.is_active}
                            />
                            {bank.is_active && (
                              <span className="ml-3 px-2 py-1 text-xs text-white bg-green-500 rounded-full">
                                Active
                              </span>
                            )}
                          </div>
                          {bank &&
                            bank.country &&
                            bank.country.length > 0 &&
                            bank.country[0].flagUrl && (
                              <img
                                src={bank.country[0].flagUrl}
                                alt="Country Flag"
                                className="w-8 h-8 rounded-full"
                              />
                            )}
                        </div>

                        <div className="grid grid-cols-2 gap-4 text-sm">
                          <div>
                            <span className="font-semibold text-gray-700">
                              Full Name:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {bank.beneficiary.ReceiverFirstName}{" "}
                              {bank.beneficiary.ReceiverLastName}
                            </span>
                          </div>
                          <div>
                            <span className="font-semibold text-gray-700">
                              Ac. No.:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {formattedAccountNumber}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm mt-3">
                          <span className="font-semibold text-gray-700">
                            Bank Name:
                          </span>
                          <span className="ml-2 text-gray-600">
                            {bank.BankName}
                          </span>
                        </div>
                        <div className="grid grid-cols-2 gap-4 text-sm mt-3">
                          <div>
                            <span className="font-semibold text-gray-700">
                              Currency:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {bank?.PayoutCurrency}
                            </span>
                          </div>{" "}
                          <div>
                            <span className="font-semibold text-gray-700">
                              Country:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {bank?.beneficiary?.country}
                            </span>
                          </div>{" "}
                          <div>
                            <span className="font-semibold text-gray-700">
                              City:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {bank?.beneficiary?.addressDetail?.city}
                            </span>
                          </div>{" "}
                          <div>
                            <span className="font-semibold text-gray-700">
                              State:
                            </span>
                            <span className="ml-2 text-gray-600">
                              {bank?.beneficiary?.addressDetail?.state}
                            </span>
                          </div>{" "}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="d-flex justify-center items-center min-h-[50vh] text-gray-600"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    No account found
                  </div>
                )}
              </div>
            </PerfectScrollbar>
          </div>
        )}
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default BankAccounts;
