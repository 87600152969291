import React, { useEffect, useRef, useState } from "react";
import "./Property.css";
import skyscraper from "../../images/9395415b505c7d6c22c6cca730874224.jpg";
import "react-phone-input-2/lib/bootstrap.css";
import ContactInfo from "./ContactInfo";
import Communication from "./Communication";
import InvestmentPreferences from "./InvestmentPreferences";
import PaymentMethod from "./PaymentMethod";
import Consent from "./Consent";
import { AddPropertyAPI, GetPropertyAPI } from "../../services/property";
import Complete from "./Complete";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import Bentley from "../../images/properties/Bentley.png";
import Emaar_logo from "../../images/properties/Emaar_logo.svg";
import Fairmont from "../../images/properties/Fairmont.svg";
import Regis from "../../images/properties/st-regis.svg";
import Bvlgari from "../../images/properties/bvlgari.svg";
import Ellington from "../../images/properties/ellington.svg";
import Rixos from "../../images/properties/rixos.svg";
import Marquee from "react-fast-marquee";

const marqueeImages = [
  // { src: Bentley, alt: "Bentley" },
  { src: Emaar_logo, alt: "Emaar" },
  { src: Fairmont, alt: "Fairmont" },
  { src: Regis, alt: "Regis" },
  { src: Bvlgari, alt: "Bvlgari" },
  { src: Ellington, alt: "Ellington" },
  { src: Rixos, alt: "Rixos" },
];

const cryptoList = ["₿", "Ξ", "⟠", "₮"];
const fiatSymbols = ["¥", "£", "$", "€"];

function SlotMachine({ items }) {
  const containerRef = useRef(null);
  const [topPosition, setTopPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * items.length);
      setTopPosition(-randomIndex * 40);
    }, 2000);

    return () => clearInterval(interval);
  }, [items]);

  return (
    <div className="slottt-machine-recipe__mask ml-2 pt-1">
      <div
        className="slottt-machine-recipe__items_container"
        ref={containerRef}
        style={{ top: `${topPosition}px`, transition: "top 0.5s ease-in-out" }}
      >
        {items.map((item, index) => (
          <div key={index} className="slottt-machine-recipe__item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

function Property() {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(0);
  const [defaultValues, setDefaultValues] = useState({});
  const [email, setEmail] = useState(null);
  const [ipDetails, setIpDetails] = useState(null);
  const [shakeIt, setShakeIt] = useState(false);
  const contactSectionRef = useRef(null);
  const { lang } = useParams();
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#form") {
      const target = document.getElementById(hash.substring(1));
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  useEffect(() => {
    if (lang) {
      changeLanguage(lang);
    } else {
      changeLanguage("en");
    }
  }, [lang]);

  useEffect(() => {
    document.body.style.background = "black";
    return () => {
      document.body.style.background = "black";
    };
  }, []);

  const getFormDetails = (email) => {
    GetPropertyAPI(email)
      .then((res) => {
        setDefaultValues(res.data.data.data);
      })
      .catch(() => {});
  };

  const handleSubmit = (values) => {
    AddPropertyAPI(values)
      .then((res) => {
        getFormDetails(values.email);
        setEmail(values.email);
      })
      .catch(() => {});
  };

  const handleContactClick = () => {
    setShakeIt(true);
    setTimeout(() => setShakeIt(false), 1000);

    if (window.innerWidth <= 768) {
      contactSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <SimpleBar forceVisible="y" style={{ maxHeight: "100vh" }}>
      <div className="property-wrapper bg-black">
        <div className="left-section">
          <div className="logo">
            <h1>1buy.io</h1>
          </div>
          <div
            className="main-text line-by-line-animation"
            style={{ paddingRight: "5%" }}
          >
            <h1 className="line-animate">
              {t("welcome_title.invest_in")}
              <span style={{ color: "gold" }}>
                {" "}
                {t("welcome_title.exclusive_properties")}
              </span>{" "}
              {t("welcome_title.with")}
              <SlotMachine items={cryptoList} />
              {t("and")}
              <SlotMachine items={fiatSymbols} />
            </h1>
            <h2 className="line-animate">
              {t("welcome_title.discover")}
              <span style={{ color: "gold" }}>
                {" "}
                {t("welcome_title.luxury_villas")}
              </span>{" "}
              {t("welcome_title.available_investment")}
            </h2>
          </div>
          <Marquee gradient={false} speed={30} style={{ marginTop: "15%" }}>
            {marqueeImages.map((image, index) => (
              <div key={index} className="marquee-item">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="marquee-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "fallback-image.png";
                  }}
                />
              </div>
            ))}
          </Marquee>
        </div>
        <div
          className="right-section image-slide-up"
          id="form"
          ref={contactSectionRef}
        >
          <img src={skyscraper} alt="skyscraper" className="property-image" />
          {step === 0 && (
            <ContactInfo
              lang={lang}
              t={t}
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              defaultValues={defaultValues}
              setIpDetails={setIpDetails}
              shakeIt={shakeIt}
            />
          )}
          {step === 1 && (
            <Communication
              t={t}
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}
          {step === 2 && (
            <InvestmentPreferences
              t={t}
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}
          {step === 3 && (
            <PaymentMethod
              t={t}
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              ipDetails={ipDetails}
              shakeIt={shakeIt}
            />
          )}
          {step === 4 && (
            <Consent
              t={t}
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}

          {step === 5 && <Complete t={t} setStep={setStep} />}
        </div>
      </div>
      <button
        className="contact-button-fixed"
        onClick={() => {
          handleContactClick();
        }}
      >
        <span className="phone-icon">📞</span>
        {t("contact_us")}
      </button>
    </SimpleBar>
  );
}

export default Property;
