import React from "react";

function Checkout() {
  return (
    <div style={{ position: "absolute", left: "25%" }}>
      <iframe
        src="https://checkout-dev.1buy.io/buy-sell/buy-nft?product_id=3e477b67-082e-406f-9462-427b0dc6c798&api_key=0f3a8103-af2e-4979-9961-43625eece1c9"
        width="782px"
        height="542px"
        title="1buy.io"
        frameBorder="1"
        style={{ borderRadius: "24px" }}
      />
    </div>
  );
}

export default Checkout;
