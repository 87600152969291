import React, { useEffect, useState } from "react";
import { createParamsData } from "./params";
import WertModule from "@wert-io/module-react-component";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { FlapInputDataAPI } from "../services/auth";
import VerificationLoader from "../pages/VerificationLoader";

function Flap() {
  let params = window.location.search;
  let paramsValue = createParamsData(params);
  const [optionsData, setOptionsData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const privateKey = process.env.REACT_APP_WERT_PRIVATE_KEY;
  const [errorMessage, setErrorMessage] = useState("");

  const flapFunction = () => {
    setIsLoading(true);
    setErrorMessage("");
    FlapInputDataAPI({
      is_dev: paramsValue["is_dev"] ? paramsValue["is_dev"] : "no",
      receiver_address: paramsValue["wallet"],
      amount: paramsValue["amount"],
      decimal: paramsValue["decimal"] ? Number(paramsValue["decimal"]) : 18,
      token: paramsValue["token"],
      api_key: paramsValue["api_key"],
    })
      .then((res) => {
        const business = res.data.data.user;
        setUserData(business);
        if (paramsValue["is_dev"] === "yes") {
          const scOptions = {
            address: paramsValue["wallet"],
            commodity: "BNB",
            commodity_amount: Number(res.data.data.sent_amount),
            network: "BSC",
            sc_address: res.data.data.sc_address,
            sc_input_data: res.data.data.sc_input_data,
          };

          const signedData = signSmartContractData(
            scOptions,
            "0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3"
          );

          const options = {
            ...scOptions,
            partner_id: "01HE2KXEGTAT7XDRGFW3WSQQAR",
            origin: "https://sandbox.wert.io",
            is_crypto_hidden: true,
            extra: {
              item_info: {
                author: business.company_name,
                author_image_url: business.profile_image
                  ? business.profile_image
                  : "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png",
                image_url:
                  "https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/95069-1729485115566.png",
                name: paramsValue["name"]
                  ? paramsValue["name"]
                  : business.company_name,
                category: paramsValue["name"]
                  ? paramsValue["name"]
                  : business.company_name,
              },
            },
            ...signedData,
          };
          setOptionsData(options);
          setIsLoading(false);
        } else {
          const scOptions = {
            address: paramsValue["wallet"],
            commodity: process.env.REACT_APP_WERT_COMMODITY,
            commodity_amount: Number(res.data.data.sent_amount),
            network: process.env.REACT_APP_WERT_NETWORK,
            sc_address: res.data.data.sc_address,
            sc_input_data: res.data.data.sc_input_data,
          };

          const signedData = signSmartContractData(scOptions, privateKey);

          const options = {
            ...scOptions,
            partner_id: process.env.REACT_APP_WERT_PARTNER_ID,
            origin: process.env.REACT_APP_WERT_ORIGIN,
            is_crypto_hidden: true,
            extra: {
              item_info: {
                author: business.company_name,
                author_image_url: business.profile_image
                  ? business.profile_image
                  : "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png",
                image_url:
                  "https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/95069-1729485115566.png",
                name: paramsValue["name"]
                  ? paramsValue["name"]
                  : business.company_name,
                category: paramsValue["name"]
                  ? paramsValue["name"]
                  : business.company_name,
              },
            },
            ...signedData,
          };
          setOptionsData(options);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    flapFunction();
  }, []);

  return (
    <div className="centered-container">
      {isLoading ? (
        <div className="loader-container">
          <VerificationLoader />
        </div>
      ) : (
        <>
          {errorMessage && <span>{errorMessage}</span>}
          {optionsData && Object.keys(optionsData).length > 0 && (
            <WertModule
              options={{
                ...optionsData,
                click_id: clickIdFunc(userData, paramsValue),
              }}
              className="wert-container"
            />
          )}
        </>
      )}
    </div>
  );
}

export default Flap;

export const clickIdFunc = (userData, paramsValue) => {
  if (userData) {
    let extraClickIds = {
      ci: paramsValue["custom_id"] ? paramsValue["custom_id"] : "",
      cID: paramsValue["clickID"] ? paramsValue["clickID"] : "",
      caID: paramsValue["campaignID"] ? paramsValue["campaignID"] : "",
      us: paramsValue["utm_source"] ? paramsValue["utm_source"] : "",
      fn: paramsValue["full_name"] ? paramsValue["full_name"] : "",
      dob: paramsValue["date_of_birth"] ? paramsValue["date_of_birth"] : "",
      state: paramsValue["state"] ? paramsValue["state"] : "",
      ph: paramsValue["phone"] ? paramsValue["phone"] : "",
      co: paramsValue["country"] ? paramsValue["country"] : "",
      email: paramsValue["email"] ? paramsValue["email"] : "",
    };
    let click_id = {
      user_id: "",
      business_user_id: userData.user_id,
      wallet: paramsValue["wallet"],
      token: paramsValue["token"],
      qu: 1,
      ...extraClickIds,
    };
    return JSON.stringify(click_id);
  }
};
