import React, { useEffect } from "react";

function Invoice() {
  let params = window.location.search;

  useEffect(() => {
    document.body.classList.add("bg-transparent");
    document.body.style.setProperty("background-color", `#ced4da`, "important");
  }, [params]);

  return (
    <iframe
      style={{
        background: "#F7F7F7",
        border: "1px solid black",
        borderRadius: "10px",
      }}
      title="Buy or sell"
      loading="lazy"
      allow="accelerometer; autoplay; camera; gyroscope; payment"
      className="widget-container"
      src={`/invoice-widget/${params}`}
      frameBorder="0"
    ></iframe>
  );
}

export default Invoice;
