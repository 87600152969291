import React from "react";

function CalendyIframe() {
  return (
    <div className="pt-12 md:pt-12 mb-8">
      <div
        className="text-center flex justify-center"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <div className="row-start-1 row-span-2 rounded-lg text-3xl mb-6">
          <span className="font-size-34">Integrate in your Platform</span>
        </div>
        <iframe
          title="1buy.io"
          frameBorder="1"
          className="calendy-iframe"
          src="https://calendly.com/rampnalysis?embed_domain=rampnalysis.com&embed_type=Inline&hide_landing_page_details=1&hide_gdpr_banner=1"
          style={{ borderRadius: "10px", height: "52vh" }}
        />
      </div>
    </div>
  );
}

export default CalendyIframe;
