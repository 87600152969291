import React, { useState } from "react";
import { FaPencilAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiCloseLargeFill } from "react-icons/ri";

function CreateInvoice({
  items,
  values,
  errors,
  setStep,
  touched,
  userData,
  totalSum,
  handleChange,
  setFieldValue,
  contractDetail,
  handleEditItem,
  setEditingIndex,
  handleRemoveItem,
}) {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <span className="text-left font-size-32 page-title mt-4 pt-4 px-4">
        Create Invoice
      </span>

      <div
        className="mx-4 p-3"
        style={{
          background: "#EDEFF3",
          borderRadius: "18px",
          marginTop: "5%",
        }}
      >
        <span className="font-size-16 px-2 font-weight-500">Client</span>
        <div className="d-flex flex-row align-items-center justify-content-between cursor-pointer">
          <div className="d-flex flex-row align-items-center">
            <img
              className="p-2 rounded-full"
              style={{ width: "55px", height: "55px" }}
              src={
                userData.profile_image
                  ? userData.profile_image
                  : "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png"
              }
              alt="client-profile"
              height={55}
              width={55}
            />
            <div className="d-flex flex-col">
              <span className="font-size-16 font-weight-500 mx-3">
                {userData.company_name}
              </span>
              <span className="font-size-12 font-weight-500 mx-3">
                {userData.email}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="mx-4 p-3"
        style={{
          background: "#EDEFF3",
          borderRadius: "18px",
          marginTop: "5%",
        }}
      >
        <div>
          <label htmlFor="title" className="font-size-16 px-2">
            Items
          </label>
        </div>
        <div className="mt-1">
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <div
                key={index}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex flex-col">
                    <span className="font-size-16 font-weight-500 mx-2">
                      {item.itemTitle}
                    </span>
                    <span
                      className="font-size-12 mx-2"
                      style={{ color: "#6F6F6F" }}
                    >
                      QTY {item.quantity} X $ {item.price}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <span className="mr-2 font-size-14">
                    ${Number(item.quantity) * Number(item.price)}{" "}
                  </span>
                  <div
                    onClick={() => handleEditItem(index, setFieldValue)}
                    className="ml-2 mr-2 cursor-pointer font-size-14"
                  >
                    <FaPencilAlt />
                  </div>
                  <div
                    className="cursor-pointer font-size-14"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <RiCloseLargeFill />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          position: "relative",
          bottom: "15px",
          zIndex: 100,
        }}
      >
        <button
          type="button"
          onClick={() => {
            setEditingIndex(null);
            setStep(1);
          }}
          style={{
            width: "124px",
            height: "40px",
            padding: "0px",
          }}
          className="button-style font-size-16 text-center"
        >
          Add Items
        </button>
      </div>

      <div
        className="mx-4"
        style={{
          background: "#EDEFF3",
          borderRadius: "18px",
          position: "relative",
          top: "-30px",
        }}
      >
        <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
          <label
            htmlFor="title"
            className="font-size-14 label-color font-weight-500 mx-2"
          >
            Sub total
          </label>
          <label
            htmlFor="title"
            className="font-size-14 label-color font-weight-500 mx-2"
          >
            ${totalSum}
          </label>
        </div>
        {values.pay_fee === "you_pay" ? (
          <>
            <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                Fee Range
              </label>
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                {100 - Number(contractDetail?.business_percentage)}% to{" "}
                {100 - Number(contractDetail?.business_percentage_card)}%
              </label>
            </div>
          </>
        ) : (
          <>
            <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                Bank Final Total (
                {100 - Number(contractDetail?.business_percentage)}
                %)
              </label>
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                ${" "}
                {totalSum +
                  (totalSum *
                    (100 - Number(contractDetail?.business_percentage))) /
                    100}
              </label>
            </div>
            <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                Card Final Total (
                {100 - Number(contractDetail?.business_percentage_card)}
                %)
              </label>
              <label
                htmlFor="title"
                className="font-size-14 label-color font-weight-500 mx-2"
              >
                ${" "}
                {totalSum +
                  (totalSum *
                    (100 - Number(contractDetail?.business_percentage_card))) /
                    100}
              </label>
            </div>
          </>
        )}

        <div
          className="mt-3"
          style={{
            backgroundColor: "black",
            color: "white",
            borderBottomLeftRadius: "18px",
            borderBottomRightRadius: "18px",
            padding: "10px",
            cursor: "pointer",
          }}
          onClick={toggle}
        >
          {values.pay_fee === "you_pay" ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <span className="px-3 font-size-16">
                  What you get breakdown
                </span>
                <div className="mx-2">
                  {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
              <div
                className={`collapsible-content ${
                  isOpen ? "open" : "collapsed"
                }`}
              >
                <div className="d-flex justify-content-between mt-3">
                  <span className="px-3 font-size-16">
                    Bank Payment (
                    {100 - Number(contractDetail?.business_percentage)}
                    %)
                  </span>
                  <span className="px-3 font-size-16">
                    ${" "}
                    {totalSum -
                      (totalSum *
                        (100 - Number(contractDetail?.business_percentage))) /
                        100}{" "}
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <span className="px-3 font-size-16">
                    Card Payment (
                    {100 - Number(contractDetail?.business_percentage_card)}
                    %)
                  </span>
                  <span className="px-3 font-size-16">
                    ${" "}
                    {totalSum -
                      (totalSum *
                        (100 -
                          Number(contractDetail?.business_percentage_card))) /
                        100}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between">
              <span className="px-3 font-size-16">You Get</span>
              <span className="px-3 font-size-16">$ {totalSum}</span>
            </div>
          )}
        </div>
      </div>

      <div
        className="flex flex-col mx-4 mb-3"
        style={{
          borderRadius: "18px",
          position: "relative",
          top: "-20px",
        }}
      >
        <label htmlFor="notes" className="font-size-16 ml-1 mb-1">
          Notes
        </label>
        <textarea
          value={values.notes}
          style={{ minHeight: "140px" }}
          placeholder="Enter notes"
          onChange={handleChange}
          name="notes"
          className={`input-field font-size-14 py-1 ${
            errors.notes && touched.notes ? "error-border" : ""
          }`}
        />
        {errors.notes && touched.notes && (
          <div className="text-left mt-1 ml-1 text-danger font-size-14">
            {errors.notes}
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateInvoice;
