import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./Property.css";
import "react-phone-input-2/lib/bootstrap.css";
import {
  FaDollarSign,
  FaBitcoin,
  FaMoneyCheck,
  FaQuestion,
  FaArrowLeft,
} from "react-icons/fa";

const cryptoList = ["Bitcoin", "Ethereum", "Tron", "Ton", "USDC", "USDT"];
const fiatSymbols = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "FOK",
  "GBP",
  "GEL",
  "GGP",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "IMP",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JEP",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KID",
  "KMF",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STN",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TVD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XOF",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL",
];

function PaymentMethod({
  t,
  handleSubmit,
  setStep,
  email,
  defaultValues,
  ipDetails,
  shakeIt,
}) {
  const paymentMethodSubmit = (values) => {
    const data = {
      paymentType: values.paymentType,
      cryptoCurrency: values.cryptoCurrency,
      fiatCurrency: values.fiatCurrency,
      investmentPurpose: values.investmentPurpose,
      otherPurposeDetails: values.otherPurposeDetails,
      email,
    };
    handleSubmit(data);
    setStep(4);
  };

  return (
    <Formik
      initialValues={{
        paymentType: defaultValues?.paymentType || "fiat",
        cryptoCurrency: defaultValues?.cryptoCurrency || "",
        fiatCurrency: defaultValues?.fiatCurrency || ipDetails?.currency || "",
        investmentPurpose: defaultValues?.investmentPurpose || "",
        otherPurposeDetails: defaultValues?.otherPurposeDetails || "",
      }}
      validationSchema={Yup.object({
        paymentType: Yup.string().required(
          t("payment_method.validation.payment_type_required")
        ),
        cryptoCurrency: Yup.string().when("paymentType", {
          is: "crypto",
          then: () =>
            Yup.string().required(
              t("payment_method.validation.crypto_currency_required")
            ),
        }),
        fiatCurrency: Yup.string().when("paymentType", {
          is: "fiat",
          then: () =>
            Yup.string().required(
              t("payment_method.validation.fiat_currency_required")
            ),
        }),
        investmentPurpose: Yup.string().required(
          t("payment_method.validation.investment_purpose_required")
        ),
        otherPurposeDetails: Yup.string().when("investmentPurpose", {
          is: "Other",
          then: () =>
            Yup.string().required(
              t("payment_method.validation.other_purpose_required")
            ),
        }),
      })}
      onSubmit={paymentMethodSubmit}
      enableReinitialize
    >
      {({ values, touched, errors, handleSubmit }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <div className="cursor-pointer" onClick={() => setStep(2)}>
            <FaArrowLeft />
          </div>
          <span className="font-size-18 font-weight-500">
            {t("payment_method.title")}
          </span>
          <div className="search-field input-icon-wrapper">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="flex align-items-center">
                  <Field
                    type="radio"
                    name="paymentType"
                    className="mt-2 mr-2"
                    value="fiat"
                  />
                  <label className="font-size-14 text-white mt-2">
                    {t("payment_method.fiat")}
                  </label>
                </div>
                <div className="flex align-items-center ml-4">
                  <Field
                    type="radio"
                    name="paymentType"
                    className="mt-2 mr-2"
                    value="crypto"
                  />
                  <label className="font-size-14 text-white mt-2">
                    {t("payment_method.crypto_currency")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {values.paymentType === "crypto" && (
            <>
              <div className="search-field input-icon-wrapper">
                <FaBitcoin className="input-icon" />
                <Field
                  as="select"
                  id="cryptoCurrency"
                  name="cryptoCurrency"
                  className="input-field-pr"
                >
                  <option value="" className="font-size-14 text-black">
                    {t("payment_method.choose_crypto_currency")}
                  </option>
                  {cryptoList.map((crypto, index) => (
                    <option
                      key={index}
                      value={crypto}
                      className="font-size-14 text-black font-weight-500"
                    >
                      {crypto}
                    </option>
                  ))}
                </Field>
              </div>
              {touched.cryptoCurrency && errors.cryptoCurrency && (
                <div className="error-message">{errors.cryptoCurrency}</div>
              )}
            </>
          )}
          {values.paymentType === "fiat" && (
            <>
              <div className="search-field input-icon-wrapper">
                <FaDollarSign className="input-icon" />
                <Field
                  as="select"
                  id="fiatCurrency"
                  name="fiatCurrency"
                  className="input-field-pr"
                >
                  <option
                    value=""
                    className="font-size-14 text-black font-weight-500"
                  >
                    {t("payment_method.choose_fiat_currency")}
                  </option>
                  {fiatSymbols.map((fiat, index) => (
                    <option
                      key={index}
                      value={fiat}
                      className="font-size-14 text-black font-weight-500"
                    >
                      {fiat}
                    </option>
                  ))}
                </Field>
              </div>
              {touched.fiatCurrency && errors.fiatCurrency && (
                <div className="error-message">{errors.fiatCurrency}</div>
              )}
            </>
          )}

          <div className="search-field input-icon-wrapper">
            <FaMoneyCheck className="input-icon" />
            <Field
              as="select"
              id="investmentPurpose"
              name="investmentPurpose"
              className="input-field-pr"
            >
              <option value="" className="font-size-14 text-black">
                {t("payment_method.purpose_of_investment")}
              </option>
              <option value="PersonalUse" className="font-size-14 text-black">
                {t("payment_method.personal_use")}
              </option>
              <option
                value="RentalInvestment"
                className="font-size-14 text-black"
              >
                {t("payment_method.rental_investment")}
              </option>
              <option value="Resale" className="font-size-14 text-black">
                {t("payment_method.resale")}
              </option>
              <option value="Other" className="font-size-14 text-black">
                {t("payment_method.other")}
              </option>
            </Field>
          </div>
          {touched.investmentPurpose && errors.investmentPurpose && (
            <div className="error-message">{errors.investmentPurpose}</div>
          )}
          {values.investmentPurpose === "Other" && (
            <>
              <div className="search-field input-icon-wrapper">
                <FaQuestion className="input-icon" />
                <Field
                  id="otherPurposeDetails"
                  name="otherPurposeDetails"
                  type="text"
                  className="input-field-pr"
                  placeholder={t("payment_method.specify_reason")}
                />
              </div>
              {touched.otherPurposeDetails && errors.otherPurposeDetails && (
                <div className="error-message">
                  {errors.otherPurposeDetails}
                </div>
              )}
            </>
          )}

          <button type="submit" className="search-button">
            {t("payment_method.continue")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default PaymentMethod;
