import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FaPhoneAlt, FaClock, FaQuestion, FaArrowLeft } from "react-icons/fa";

function Communication({
  t,
  handleSubmit,
  setStep,
  defaultValues,
  email,
  shakeIt,
}) {
  const timeSlots = [
    { value: "morning", label: t("communication.morning") },
    { value: "afternoon", label: t("communication.afternoon") },
    { value: "evening", label: t("communication.evening") },
    { value: "night", label: t("communication.night") },
    { value: "allDay", label: t("communication.all_day") },
  ];

  const communicationSubmit = (values) => {
    const data = {
      preferredMethod: values.preferredMethod,
      timeframe: values.timeframe,
      timeSlot: values.timeSlot,
      additionalDetails: values.additionalDetails,
      email,
    };
    handleSubmit(data);
    if (values.timeframe === "immediate") {
      setStep(2);
    } else {
      setStep(4);
    }
  };

  return (
    <Formik
      initialValues={{
        preferredMethod: defaultValues?.preferredMethod || "",
        timeSlot: defaultValues?.timeSlot || "",
        timeframe: defaultValues?.timeframe || "",
        additionalDetails: defaultValues?.additionalDetails || "",
      }}
      validationSchema={Yup.object({
        preferredMethod: Yup.string().required(
          t("communication.validation.preferred_method_required")
        ),
        timeframe: Yup.string().required(
          t("communication.validation.timeframe_required")
        ),
        timeSlot: Yup.string().required(
          t("communication.validation.time_slot_required")
        ),
      })}
      enableReinitialize
      onSubmit={communicationSubmit}
    >
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <div className="cursor-pointer" onClick={() => setStep(0)}>
            <FaArrowLeft />
          </div>
          <span className="font-size-18 font-weight-500">
            {t("communication.title")}
          </span>

          <div className="search-field input-icon-wrapper">
            <FaPhoneAlt className="input-icon" />
            <select
              id="preferredMethod"
              name="preferredMethod"
              className="input-field-pr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferredMethod}
            >
              <option value="" className="font-size-14 text-black">
                {t("communication.preferred_contact")}
              </option>
              <option value="whatsapp" className="font-size-14 text-black">
                {t("communication.whatsapp")}
              </option>
              <option value="line" className="font-size-14 text-black">
                {t("communication.line")}
              </option>
              <option value="email" className="font-size-14 text-black">
                {t("communication.email")}
              </option>
              <option value="phoneCall" className="font-size-14 text-black">
                {t("communication.phone_call")}
              </option>
            </select>
          </div>
          {touched.preferredMethod && errors.preferredMethod && (
            <div className="error-message">{errors.preferredMethod}</div>
          )}

          <label className="font-size-14 text-white font-weight-500">
            {t("communication.best_time")}
          </label>
          <div className="time-slots-container d-flex flex-wrap gap-2">
            {timeSlots.map((slot) => (
              <button
                key={slot.value}
                type="button"
                className={`time-slot-button font-size-14 font-weight-500 text-black ${
                  values.timeSlot === slot.value ? "selected" : ""
                }`}
                onClick={() => setFieldValue("timeSlot", slot.value)}
              >
                {slot.label}
              </button>
            ))}
          </div>
          {touched.timeSlot && errors.timeSlot && (
            <div className="error-message">{errors.timeSlot}</div>
          )}

          <div className="search-field input-icon-wrapper">
            <FaClock className="input-icon" />
            <select
              id="timeframe"
              name="timeframe"
              className="input-field-pr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.timeframe}
            >
              <option value="" className="font-size-14 text-black">
                {t("communication.timeframe_purchase")}
              </option>
              <option value="immediate" className="font-size-14 text-black">
                {t("communication.immediate")}
              </option>
              <option value="3months" className="font-size-14 text-black">
                {t("communication.within_3_months")}
              </option>
              <option value="3-6months" className="font-size-14 text-black">
                {t("communication.three_to_six_months")}
              </option>
              <option value="6-12months" className="font-size-14 text-black">
                {t("communication.six_to_twelve_months")}
              </option>
            </select>
          </div>
          {touched.timeframe && errors.timeframe && (
            <div className="error-message">{errors.timeframe}</div>
          )}

          <div className="search-field input-icon-wrapper">
            <FaQuestion className="input-icon" />
            <input
              id="additionalDetails"
              name="additionalDetails"
              type="text"
              className="input-field-pr"
              placeholder={t("communication.additional_details")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.additionalDetails}
              autoComplete="off"
            />
          </div>
          {touched.additionalDetails && errors.additionalDetails && (
            <div className="error-message">{errors.additionalDetails}</div>
          )}

          <button type="submit" className="search-button">
            {t("communication.continue")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default Communication;
