import React, { useEffect, useState } from "react";
import { BackSVG } from "../../../App";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import { UserInvoiceVerificationAPI } from "../../../services/auth";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../../services/notificationConst";
import { client } from "../client";
import { useConnect } from "thirdweb/react";
import { inAppWallet } from "thirdweb/wallets";
import { preAuthenticate } from "thirdweb/wallets/in-app";

function Verification({ activeWallet }) {
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState("");
  const history = useHistory();
  let params = window.location.search;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { connect } = useConnect();
  const handleSubmit = async () => {
    const email = location.state.email || localStorage.getItem("email");
    setIsLoading(true);
    await connect(async () => {
      const wallet = inAppWallet();
      await wallet
        .connect({
          client: client,
          strategy: "email",
          email: email,
          verificationCode: otp,
        })
        .catch((err) => {
          setIsLoading(false);
          const errorMessage = err?.message;
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: "12px" },
          });
        });

      return wallet;
    })
      .then((response) => {
        let data = {
          email: localStorage.getItem("email"),
          address: response?.getAccount()?.address,
        };
        UserInvoiceVerificationAPI(data)
          .then((res) => {
            localStorage.setItem(
              "ACCESSTOKEN",
              res?.data?.data?.data?.accessToken
            );

            if (res.data.data.data.invoice_deposit_wallet) {
              history.push(`/invoice-widget/${params}`);
            }
            if (
              res.data.data.data.invoice_deposit_wallet === undefined ||
              res.data.data.data.invoice_deposit_wallet === ""
            ) {
              history.push({
                pathname: `/invoice-wallet`,
                state: { isLogin: true },
              });
            }

            setIsLoading(false);
            toast.success("Email verified successfully", {
              style: { fontSize: "12px" },
            });
          })
          .catch((err) => {
            setIsLoading(false);
            const errorMessage = err?.data?.error?.message;
            toast.error(notificationMessageFunction(errorMessage), {
              style: { fontSize: "12px" },
            });
          });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  useEffect(() => {
    setShowError("");
  }, [otp]);

  async function handleResend() {
    const email = location.state.email || localStorage.getItem("email");
    await preAuthenticate({
      client,
      strategy: "email",
      email: email.toLowerCase(),
    });
    toast.success("Verify email sent successfully to your email", {
      style: { fontSize: "12px" },
    });
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7", overflow: "hidden" }}>
          <div className="mt-4 mb-3 flex pl-3">
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push(`/invoice-login/${params}`);
              }}
            >
              {BackSVG}
            </div>{" "}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">OTP</span>
            </div>
          </div>
          <div className="ml-3 pl-1 mt-3">
            <p className="font-size-18 font-weight-500 mt-2">
              OTP Verification
            </p>
            <p className="font-size-14">
              An OTP code has been sent to your email.
            </p>
            <p className="font-size-14">
              Please submit the OTP for verification.
            </p>
          </div>
          <div className="ml-3 pl-1 mt-3">
            <span className="font-size-14 font-weight-500">Code</span>
            <div className="d-flex justify-content-start mt-3">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                containerStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  maxWidth: "400px",
                  margin: "40 auto",
                }}
                inputStyle={{
                  width: "55px",
                  height: "60px",
                  borderRadius: "8px",
                  border: "0px solid #ced4da",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#000",
                  backgroundColor: "#EDEFF3",
                  outline: "none",
                }}
                focusStyle={{
                  border: "1px solid #007bff",
                  boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
                }}
                renderSeparator={<span style={{ marginRight: "10px" }}> </span>}
                renderInput={(props) => (
                  <input {...props} inputMode="numeric" type="tel" />
                )}
              />
            </div>
            {showError && (
              <span className="font-size-14 text-danger font-weight-500">
                {showError}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <span
              className="font-size-18 font-weight-500 cursor-pointer"
              onClick={handleResend}
            >
              Resend
            </span>
          </div>
          <div
            className="flex justify-center p-4 button-div"
            style={{ position: "fixed", bottom: "5px" }}
          >
            <button
              id="login-button"
              type="button"
              className="button-style"
              onClick={() => handleSubmit()}
            >
              <div className="font-size-14">Submit</div>
            </button>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default Verification;
