import React from "react";
import { Link } from "react-router-dom";
import LogoW from "./LogoW";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer style={{ background: "black" }} className="bg-no-repeat bg-cover">
      <div className="container mx-auto px-4 sm:px-6 py-8 md:py-12">
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-8 text-white flex"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div className="col-span-1 lg:col-span-3 lg:mb-0">
            <Link to="/" className="inline-block" aria-label="Cruip">
              <LogoW />
            </Link>
          </div>
          <div className="flex" style={{ alignItems: "center" }}>
            <a
              href="https://twitter.com/1buyio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 378 387"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M221.181 174.003L345.147 33H315.771L208.13 155.431L122.159 33H23L153.006 218.137L23 366H52.3775L166.048 236.709L256.841 366H356L221.173 174.003H221.18H221.181ZM180.943 219.768L167.771 201.332L62.9633 54.6399H108.086L192.667 173.026L205.839 191.462L315.785 345.345H270.662L180.943 219.775V219.768Z"
                  fill="white"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.brandie.link/1buyio"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <span style={{ color: "white", fontWeight: "400", fontSize: 34 }}>
                B
              </span>
            </a>
          </div>
        </div>

        <div className="text-center md:text-left text-white md:flex md:items-center md:justify-between mt-8 pt-4 border-t border-gray-200">
          <div>
            <p>© {year} 1buy.io Limited</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

// https://twitter.com/1buyio
// https://www.brandie.link/1buyio
