import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function RedirectTest() {
  const { hash } = useParams();
  return (
    <div>
      <iframe
        src={`https://checkout-dev.1buy.io/buy-nft?hash=${hash}`}
        height={700}
        width={500}
        title="t"
      />
    </div>
  );
}

export default RedirectTest;
