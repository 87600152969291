import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import "react-phone-input-2/lib/bootstrap.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  GetCountriesAPI,
  UpdateBusinessProfileAPI,
} from "../../../services/auth";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../../services/notificationConst";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  getInvoiceSmartContractAPI,
  GetMyProfileAPI,
} from "../../../services/invoice";
import { BackSVG } from "../../../App";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const inputStyle = {
  width: "100%",
  backgroundColor: "#EDEFF3",
  paddingLeft: "50px",
  border: "0px solid",
  borderRadius: "16px",
  fontSize: "14px",
  height: "55px",
  color: "#1F2937",
  marginLeft: "10px",
};

const buttonStyle = {
  background: "none",
  border: "none",
  outline: "none",
  paddingLeft: "10px",
  paddingRight: "10px",
};

const dropdownStyle = {
  fontSize: "16px",
  fontWeight: "500",
  border: "1px solid #E5E7EB",
  backgroundColor: "#FFFFFF",
  maxHeight: "300px",
  width: "350px",
  overflowY: "auto",
};

const searchStyle = {
  fontSize: "14px",
  padding: "10px",
  border: "1px solid #E5E7EB",
  marginBottom: "8px",
};

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long")
    .max(50, "First name must be less than 50 characters long"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long")
    .max(50, "Last name must be less than 50 characters long"),
  phone_number: Yup.string()
    .required("Please enter phone number")
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(8, "Phone number must be at least 8 characters"),
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters long")
    .max(100, "Address must be less than 100 characters long"),
  country: Yup.string().required("Country is required"),
});

function BusinessInfoPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [countries, setCountries] = useState([]);
  const [smartContractData, setSmartContractData] = useState({});
  const history = useHistory();
  const location = useLocation();

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        if (
          !(
            res.data.data.data.phone_number &&
            res.data.data.data.company_name &&
            res.data.data.data.industry_type &&
            res.data.data.data.industry
          )
        ) {
          history.push(`/invoice-business-info`);
        }

        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getCountriesFunc = () => {
    GetCountriesAPI()
      .then((res) => {
        if (res.data.data.data) {
          const options = res.data.data.data
            .map((country) => ({
              label: country.name,
              value: country.alpha3,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
          setCountries(options);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCountriesFunc();
  }, []);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      ...values,
      email: userData.email.toLowerCase(),
      code: "",
    };

    UpdateBusinessProfileAPI(data)
      .then((res) => {
        // setTimeout(() => {
        //   toast.success("Your details updated successfully");
        // }, 100);
        localStorage.setItem("EMAIL", data.email);
        setIsLoading(false);
        history.push({
          pathname: `/invoice-business-info1`,
          state: {
            email: data.email,
            isFromSidebar: location?.state?.isFromSidebar,
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage));
      });
  };

  const getContractDetails = () => {
    getInvoiceSmartContractAPI()
      .then((res) => {
        const data = res?.data?.data?.data;
        if (data && Object.keys(data).length > 0) {
          setSmartContractData(data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getContractDetails();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
            {location?.state?.isFromSidebar && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  history.push("/settings");
                }}
              >
                {BackSVG}
              </div>
            )}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">
                Personal Info
              </span>
            </div>
          </div>
          <PerfectScrollbar
            autoHide={true}
            style={{
              maxHeight: "calc(100vh - 120px)",
              overflowX: "hidden",
            }}
          >
            {" "}
            <div className="mt-2" style={{ marginBottom: "40px" }}>
              <Formik
                initialValues={{
                  email: userData?.email || "",
                  first_name: userData?.first_name || "",
                  last_name: userData?.last_name || "",
                  address: userData?.address || "",
                  phone_number: userData?.phone_number || "",
                  country: userData?.country || "",
                  customerType: userData?.customerType || "",
                }}
                validationSchema={SignupSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-2 flex flex-col px-4 pt-2">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        value={values.email}
                        placeholder="Enter email"
                        onChange={handleChange}
                        name="email"
                        className={`input-field font-size-14 ${
                          errors.email && touched.email ? "error-border" : ""
                        }`}
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.email}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 flex flex-col px-4 pt-2">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        First Name*
                      </label>
                      <input
                        type="text"
                        value={values.first_name}
                        placeholder="Enter first name"
                        onChange={handleChange}
                        name="first_name"
                        className={`input-field font-size-14 ${
                          errors.first_name && touched.first_name
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.first_name && touched.first_name && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.first_name}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 flex flex-col px-4 pt-2">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Last Name*
                      </label>
                      <input
                        type="text"
                        value={values.last_name}
                        placeholder="Enter last name"
                        onChange={handleChange}
                        name="last_name"
                        className={`input-field font-size-14 ${
                          errors.last_name && touched.last_name
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.last_name && touched.last_name && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.last_name}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Customer Type*
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "14px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-weight-500 font-size-14 ${
                          errors.customerType && touched.customerType
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Customer Type"
                        classNamePrefix="select-input"
                        options={[
                          { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                          { label: "BUSINESS", value: "BUSINESS" },
                        ]}
                        value={[
                          { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                          { label: "BUSINESS", value: "BUSINESS" },
                        ].find((c) => c.value === values.customerType)}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("customerType", val.value);
                          } else {
                            setFieldValue("customerType", "");
                          }
                        }}
                        isDisabled={
                          smartContractData &&
                          Object.keys(smartContractData).length > 0
                            ? true
                            : false
                        }
                        name="customerType"
                      />
                      {errors.customerType && touched.customerType && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.customerType}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-2 pr-6">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 px-2 font-weight-500"
                      >
                        Phone Number*
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={values.phone_number}
                        inputStyle={{
                          ...inputStyle,
                          border: `${
                            errors.phone_number && touched.phone_number
                              ? "1px solid red"
                              : "0px"
                          }`,
                        }}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("phone_number", val);
                          } else {
                            setFieldValue("phone_number", "");
                          }
                        }}
                        buttonStyle={buttonStyle}
                        dropdownClass="font-size-16"
                        dropdownStyle={dropdownStyle}
                        specialLabel=""
                        searchStyle={searchStyle}
                      />

                      {errors.phone_number && touched.phone_number && (
                        <div className="text-left mt-1 ml-2 px-2 text-danger font-size-14">
                          {errors.phone_number}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Select Country*
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "14px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-weight-500 font-size-14 ${
                          errors.country && touched.country
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Country"
                        classNamePrefix="select-input"
                        options={countries}
                        value={countries.find(
                          (o) => o.value === values.country
                        )}
                        onChange={(val) => {
                          setFieldValue("country", val ? val.value : "");
                        }}
                        name="country"
                      />
                      {errors.country && touched.country && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.country}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Address*
                      </label>{" "}
                      <input
                        type="text"
                        value={values.address}
                        placeholder="Enter address"
                        onChange={handleChange}
                        name="address"
                        className={`input-field font-size-14 ${
                          errors.address && touched.address
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.address && touched.address && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.address}
                        </div>
                      )}
                    </div>

                    <div
                      className="flex justify-center p-4 button-div"
                      style={{ position: "fixed", bottom: "5px" }}
                    >
                      <button
                        id="login-button"
                        type="submit"
                        className="button-style"
                      >
                        <div className="font-size-14">Continue</div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </PerfectScrollbar>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default BusinessInfoPage;
