import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { GetMyProfileAPI } from "../../../services/invoice";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  WindBankDetailsAPI,
  WindRequiredFieldsAPI,
} from "../../../services/auth";
import { notificationMessageFunction } from "../../../services/notificationConst";
import { BackSVG } from "../../../App";

const BeneficiaryDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const createValidationSchema = () => {
    const shape = {};
    formFields.forEach((field) => {
      if (field.isRequired && field.fieldName !== "CustomerId") {
        let validator = Yup.string().required(
          `${field.fieldLabel.replace("*", "")} is required`
        );
        if (field.minLength) validator = validator.min(field.minLength);
        if (field.maxLength) validator = validator.max(field.maxLength);
        if (field.validate === "IS_EMAIL") {
          validator = Yup.string()
            .email("Invalid email format")
            .required(`${field.fieldLabel.replace("*", "")} is required`);
        }

        if (field.validate === "IS_PHONE_NUMBER") {
          validator = Yup.string()
            .matches(/^[0-9]+$/, "Must be a valid phone number")
            .required(`${field.fieldLabel.replace("*", "")} is required`);
        }

        shape[field.fieldName] = validator;
      } else {
        shape[field.fieldName] = Yup.string();
      }
    });

    return Yup.object().shape(shape);
  };

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        const data = res.data.data.data;
        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
        console.error("Error fetching user profile");
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getRequiredFields = () => {
    setIsLoading(true);
    WindRequiredFieldsAPI(location.state)
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        setFormFields(data);
      })
      .catch(() => {
        setIsLoading(false);
        console.error("Error fetching user profile");
      });
  };

  useEffect(() => {
    getRequiredFields();
  }, []);

  const initialValues = formFields.reduce((acc, field) => {
    acc[field.fieldName] =
      (location.state && location.state[field.fieldName]) ||
      userData[field.fieldName] ||
      (field.fieldName === "ReceiverEmail" ? userData.email : "") ||
      (field.fieldName === "ReceiverFirstName" ? userData?.first_name : "") ||
      (field.fieldName === "ReceiverLastName" ? userData?.last_name : "") ||
      (field.fieldName === "ReceiverContactNumber"
        ? userData.phone_number
        : "") ||
      (field.fieldName === "SenderSourceOfFund" ? "02" : "") ||
      (field.fieldName === "PaymentMode" ? "BANK" : "") ||
      (field.fieldName === "BeneficiaryType" &&
      userData?.customerType === "INDIVIDUAL"
        ? "I"
        : field.fieldName === "BeneficiaryType" &&
          userData?.customerType === "BUSINESS"
        ? "B"
        : "") ||
      (field.fieldName === "developmentFeePercentage" ? 0 : "") ||
      (field.fieldName === "developmentFeeFixed" ? 0 : "");
    return acc;
  }, {});

  const handleFieldChange = (val, setFieldValue, fieldName) => {
    setFieldValue(fieldName, val ? val.value : "");
  };
  const sortedFormFields = [...formFields].sort(
    (a, b) => (a.order || Infinity) - (b.order || Infinity)
  );

  const handleSubmit = (values) => {
    let data = { ...values, BankBranchCode: location.state.BankBranchCode };

    if (values.BankBranchCode) {
      data = { ...values, BankBranchCode: values.BankBranchCode };
    }

    WindBankDetailsAPI(data)
      .then((res) => {
        history.push({
          pathname: "/invoice-bank-accounts",
          state: values,
        });
        toast.success("Details added successfully", {
          style: { fontSize: "12px" },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err?.data?.error?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push(`/invoice-bank-details`);
              }}
            >
              {BackSVG}
            </div>
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">
                Beneficiary Details
              </span>
            </div>
          </div>
          <PerfectScrollbar
            forceVisible="y"
            autoHide={true}
            style={{
              maxHeight: "calc(100vh - 140px)",
              overflowX: "hidden",
            }}
          >
            <div className="mt-2">
              <Formik
                initialValues={initialValues}
                validationSchema={createValidationSchema()}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  touched,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {sortedFormFields.map((field) => (
                      <>
                        {!field.hidden &&
                          field.isRequired &&
                          field.fieldName !== "BeneficiaryType" &&
                          field.fieldName !== "developmentFeePercentage" &&
                          field.fieldName !== "SenderSourceOfFund" &&
                          field.fieldName !== "developmentFeeFixed" && (
                            <div
                              key={field.fieldName}
                              className="mb-2 flex flex-col px-4 pt-2"
                            >
                              <label
                                htmlFor={field.fieldName}
                                className="font-size-14 ml-1 mb-1 font-weight-500"
                              >
                                {field.fieldLabel}
                              </label>
                              {field.allowedValues ? (
                                <Select
                                  styles={{
                                    menuList: (provided) => ({
                                      ...provided,
                                      maxHeight: "300px",
                                      fontSize: "14px",
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 10000,
                                    }),
                                  }}
                                  className={`select-input font-weight-500 font-size-14 ${
                                    errors[field.fieldName] &&
                                    touched[field.fieldName]
                                      ? "error-border"
                                      : ""
                                  }`}
                                  placeholder={field.waterMark}
                                  classNamePrefix="select-input"
                                  options={field.allowedValues
                                    .filter(
                                      (option) =>
                                        option.beneficiaryType === "B" ||
                                        option.remitterType === "B" ||
                                        (!option.beneficiaryType &&
                                          !option.remitterType)
                                    )
                                    .map((option) => ({
                                      label: option.value,
                                      value: option.data,
                                    }))}
                                  value={field.allowedValues
                                    .map((option) => ({
                                      label: option.value,
                                      value: option.data,
                                    }))
                                    .find(
                                      (o) => o.value === values[field.fieldName]
                                    )}
                                  onChange={(val) =>
                                    handleFieldChange(
                                      val,
                                      setFieldValue,
                                      field.fieldName
                                    )
                                  }
                                  name={field.fieldName}
                                />
                              ) : (
                                <input
                                  type={
                                    field.type === "text" ? "text" : field.type
                                  }
                                  name={field.fieldName}
                                  value={values[field.fieldName]}
                                  placeholder={field.waterMark}
                                  onChange={handleChange}
                                  className={`input-field font-size-14 ${
                                    errors[field.fieldName] &&
                                    touched[field.fieldName]
                                      ? "error-border"
                                      : ""
                                  }`}
                                />
                              )}

                              {errors[field.fieldName] &&
                                touched[field.fieldName] && (
                                  <div className="text-left mt-1 ml-2 text-danger font-size-14">
                                    {errors[field.fieldName]}
                                  </div>
                                )}
                            </div>
                          )}
                      </>
                    ))}
                    <div
                      className="flex justify-center px-4 pb-4 button-div"
                      style={{ position: "fixed", bottom: "5px" }}
                    >
                      <button
                        id="login-button"
                        type="submit"
                        className="button-style"
                      >
                        <div className="font-size-14">Submit</div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </PerfectScrollbar>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
};

export default BeneficiaryDetails;
