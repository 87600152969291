import React from "react";
import connext from "../images/connext.svg";
import benqi from "../images/benqi.svg";
import okx from "../images/okx.svg";
import goldman from "../images/goldman.svg";
import morgan from "../images/jp-morgan.svg";
import coinmarketcap from "../images/coinmarketcap.svg";
import alphapoint from "../images/alphapoint.svg";
import ov from "../images/ov.png";
import warburgserres from "../images/warburg-serres.svg";
import Swing_XYZ2 from "../images/Swing_XYZ2.svg";
import folks from "../images/folks1.svg";
import neworder from "../images/neworder.svg";

function InvestedBy() {
  return (
    <div className="pt-8 md:pt-8">
      <div
        className="text-center flex justify-center"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <div className="row-start-1 row-span-2 rounded-lg text-3xl mb-6">
          <span className="font-size-34 font-weight-500">Invested By</span>
        </div>
        <div
          id="main"
          className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4"
        >
          <CommonInvestedBy image={connext} text={"CTO & Co Founder"} />
          <CommonInvestedBy image={benqi} text={"Co Founder"} />
          <CommonInvestedBy image={okx} text={"Strategic Partnerships Lead"} />
          <CommonInvestedBy image={goldman} text={"Former Trader"} />
          <CommonInvestedBy image={morgan} text={"Former VP"} />
          <CommonInvestedBy image={coinmarketcap} text={"Former CTO"} />
          <CommonInvestedBy
            image={alphapoint}
            text={"Former Director of Payments"}
          />
          <CommonInvestedBy image={ov} text={""} />
          <CommonInvestedBy image={warburgserres} text={"GP"} />
          <CommonInvestedBy image={Swing_XYZ2} text={"Founder & CEO"} />
          <CommonInvestedBy image={folks} text={"Founder & CEO"} />
          <CommonInvestedBy image={neworder} text={""} />
        </div>
      </div>
    </div>
  );
}

export default InvestedBy;

const CommonInvestedBy = ({ image, text }) => {
  return (
    <div
      className="invested-by-card"
      style={{
        // width: 288,
        // height: 217,
        background: "#F5F5F5",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img style={{ width: 158 }} alt="" src={image} />
      <span style={{ fontSize: "10px" }} className="mt-2">
        {text}
      </span>
    </div>
  );
};
