import React, { useEffect, useState } from "react";
import axios from "axios";
import Marquee from "react-fast-marquee";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function CoinsMarquee({ setDefaultCrypto }) {
  const [coinData, setCoinData] = useState([]);

  const [selectedOption, setSelectedOption] = useState(
    "market_cap_change_percentage_24h"
  );
  const getCoinGeckoAPIFunc = async () => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=pancakeswap,bob-token,dogelon-mars,dogebonk,meme,tamadoge,pepe,shiba-inu,bonk,dogecoin,floki,coq-inu,kimbo,zoomer,polydoge,apecoin,cyber-doge-2,cairo-bank"
      )
      .then((res) => {
        const coinGeckoList = [];
        for (const coinItem of res.data) {
          const coinDataItem = getCoinClassName(coinItem.id);
          if (coinDataItem) {
            coinGeckoList.push({ ...coinItem, coin_symbol: coinDataItem });
          } else {
            coinGeckoList.push({ ...coinItem });
          }
        }
        addDataIntoCache(
          "allCoinGeckoList",
          "https://1buy.io",
          JSON.stringify(coinGeckoList)
        );
        setCoinData(coinGeckoList);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const timer = setInterval(() => {
      getCoinGeckoAPIFunc();
    }, 60 * 60 * 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getCoinGeckoAPIFunc();
  }, []);

  return (
    <div>
      {coinData && coinData.length > 0 && (
        <>
          <div
            className="flex"
            style={{ justifyContent: "end", alignItems: "center" }}
          >
            <span
              className="ml-2 mr-2 cursor-pointer"
              style={{
                fontSize: "12px",
                fontWeight: `${
                  selectedOption === "market_cap_change_percentage_24h"
                    ? "600"
                    : "400"
                }`,
              }}
              onClick={() =>
                setSelectedOption("market_cap_change_percentage_24h")
              }
            >
              24h Market cap Change
            </span>{" "}
            <p>|</p>
            <span
              className="ml-2 mr-2 cursor-pointer"
              style={{
                fontSize: "12px",
                fontWeight: `${
                  selectedOption === "price_change_percentage_24h"
                    ? "600"
                    : "400"
                }`,
              }}
              onClick={() => setSelectedOption("price_change_percentage_24h")}
            >
              24h Price Change
            </span>{" "}
            <p>|</p>
            <span
              className="ml-2 mr-2 cursor-pointer"
              style={{
                fontSize: "12px",
                fontWeight: `${
                  selectedOption === "current_price" ? "600" : "400"
                }`,
              }}
              onClick={() => setSelectedOption("current_price")}
            >
              Current Price(USD)
            </span>
          </div>

          <div className="mb-8 mt-6">
            <Marquee direction="right" autoFill={true} pauseOnHover>
              {coinData.map((coin, key) => (
                <div>
                  <CoinDetail
                    coin={coin}
                    selectedOption={selectedOption}
                    setDefaultCrypto={setDefaultCrypto}
                  />
                </div>
              ))}
            </Marquee>
          </div>
          <div className="mb-6">
            <Marquee direction="left" autoFill={true} pauseOnHover>
              {coinData.map((coin, key) => (
                <div>
                  <CoinDetail
                    coin={coin}
                    selectedOption={selectedOption}
                    setDefaultCrypto={setDefaultCrypto}
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </>
      )}
    </div>
  );
}

export default CoinsMarquee;

const CoinDetail = ({ coin, selectedOption, setDefaultCrypto }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      className={`ml-2 ${coin.coin_symbol ? "cursor-pointer" : ""}`}
      title="Click to buy"
      onClick={() => {
        if (coin && coin.coin_symbol) {
          window.scrollTo(0, 0);
          setDefaultCrypto(coin.coin_symbol);
        }
      }}
    >
      <img
        src={coin.image}
        alt={coin.name}
        className="mr-2"
        style={{ borderRadius: "50%", height: "20px", width: "20px" }}
      />
      <span style={{ fontSize: "10px" }}>{coin.name}</span>
      <span
        className="ml-2"
        style={{
          fontSize: "10px",
          color: `${
            coin[selectedOption] > 0
              ? "green"
              : coin[selectedOption] < 0
              ? "red"
              : "black"
          }`,
        }}
      >
        {coin[selectedOption] && coin[selectedOption].toFixed(10)}
        {selectedOption === "current_price" ? "" : "%"}
        {coin[selectedOption] > 0 ? (
          <ArrowDropUpIcon />
        ) : coin[selectedOption] < 0 ? (
          <ArrowDropDownIcon />
        ) : (
          ""
        )}
      </span>
      <p className="ml-2">|</p>
    </div>
  );
};

export const addDataIntoCache = (cacheName, url, response) => {
  const data = new Response(JSON.stringify(response));
  console.log(data);
  if ("caches" in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};

export const getAllCacheData = async (cacheName) => {
  try {
    const cache = await caches.open(cacheName);
    const cachedResponses = await cache.keys();

    let cacheDataArray = [];
    if (cachedResponses && cachedResponses.length > 0) {
      const response = await cache.match(cachedResponses[0]);
      const data = await response.json();
      if (data && data.length > 0) {
        cacheDataArray = JSON.parse(data);
      }
    }

    return cacheDataArray;
  } catch (error) {
    console.error("Error fetching cache data:", error);
  }
};

function getCoinClassName(coinSymbol) {
  switch (coinSymbol) {
    case "bob-token":
      return "BOB_ETH";
    case "dogelon-mars":
      return "ELON_ETH";
    case "dogebonk":
      return "DOBO_BSC";
    case "meme":
      return "MEME_ETH";
    case "tamadoge":
      return "TAMA_ETH";
    case "pepe":
      return "PEPE_ETH";
    case "shiba-inu":
      return "SHIB_ETH";
    case "bonk":
      return "BONK_SOL";
    case "dogecoin":
      return "DOGE";
    case "floki":
      return "FLOKI_BSC";
    case "coq-inu":
      return "COQ_AVAXCCHAIN";
    case "kimbo":
      return "KIMBO_AVAXCCHAIN";
    case "zoomer":
      return "ZOOMER_ETH";
    case "polydoge":
      return "POLYDOGE_POLYGON";
    case "apecoin":
      return "APE_ETH";
    case "cairo-bank":
      return "CBANK_BSC";
    case "bao-finance":
      return "BAO_ETH";
    case "pancakeswap":
      return "CAKE_ETH";
    default:
      return "";
  }
}
