import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./Property.css";
import "react-phone-input-2/lib/bootstrap.css";
import { FaArrowLeft } from "react-icons/fa";

function Consent({ t, handleSubmit, setStep, email, defaultValues, shakeIt }) {
  const consentSubmit = (values) => {
    const data = {
      agreeTerms: values.agreeTerms,
      applyUpdates: values.applyUpdates,
      email,
    };
    handleSubmit(data);
    setStep(5);
  };

  return (
    <Formik
      initialValues={{
        agreeTerms: defaultValues?.agreeTerms || false,
        applyUpdates: defaultValues?.applyUpdates || false,
      }}
      validationSchema={Yup.object({
        agreeTerms: Yup.boolean().oneOf(
          [true],
          t("consent.validation.agree_terms_required")
        ),
      })}
      onSubmit={consentSubmit}
    >
      {({ touched, errors, handleSubmit }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <div
            className="cursor-pointer"
            onClick={() => {
              if (defaultValues.timeframe === "immediate") {
                setStep(3);
              } else {
                setStep(1);
              }
            }}
          >
            <FaArrowLeft />
          </div>
          <span className="font-size-20 font-weight-500">
            {t("consent.title")}
          </span>
          <div className="flex flex-col mt-4">
            <label className="flex items-center">
              <Field type="checkbox" name="agreeTerms" className="mr-2" />
              <span className="text-white font-size-12">
                {t("consent.agree_terms")}
              </span>
            </label>
          </div>
          {touched.agreeTerms && errors.agreeTerms && (
            <div className="error-message">{errors.agreeTerms}</div>
          )}
          <div className="flex flex-col mt-4">
            <label className="flex items-center">
              <Field type="checkbox" name="applyUpdates" className="mr-2" />
              <span className="text-white font-size-12">
                {t("consent.apply_updates")}
              </span>
            </label>
          </div>
          <button type="submit" className="search-button">
            {t("consent.complete")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default Consent;
