import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { BackSVG } from "../../../App";
import {
  CreateInvoiceAPI,
  getInvoiceSmartContractAPI,
  GetMyProfileAPI,
} from "../../../services/invoice";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { notificationMessageFunction } from "../../../services/notificationConst";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import InvoiceLinkDrawer from "../InvoiceLinkDrawer";
import { GetWindBeneficiaryAPI } from "../../../services/auth";
import InvoiceTitle from "./InvoiceTitle";
import AddItems from "./AddItems";
import CreateInvoice from "./CreateInvoice";

const initialValidationSchema = Yup.object().shape({
  title: Yup.string().required("Invoice title is required"),
  pay_fee: Yup.string().required("Fee payment selection is required"),
});

const itemValidationSchema = Yup.object().shape({
  itemTitle: Yup.string().required("Item title is required"),
  quantity: Yup.number()
    .required("Quantity is required")
    .min(1, "At least 1 item is required"),
  price: Yup.number()
    .required("Price is required")
    .positive("Price must be greater than zero"),
  description: Yup.string().required("Note is required"),
});

function FullInvoice() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const [userData, setUserData] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [contractDetail, setContractDetail] = useState(null);
  const [invoiceData, setInvoiceData] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState({});

  const getContractDetail = () => {
    setIsLoading(true);
    getInvoiceSmartContractAPI()
      .then((res) => {
        const data = res.data.data.data;
        setContractDetail(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContractDetail();
    getBeneficiary();
    getMyProfile();
  }, []);

  const getBeneficiary = () => {
    setIsLoading(true);
    GetWindBeneficiaryAPI()
      .then((res) => {
        setIsLoading(false);
        setBeneficiaryData(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        setUserData(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleAddItem = (values, setFieldValue) => {
    if (editingIndex !== null) {
      const updatedItems = [...items];
      updatedItems[editingIndex] = {
        itemTitle: values.itemTitle,
        quantity: values.quantity,
        price: values.price,
        description: values.description,
      };
      setItems(updatedItems);
      setEditingIndex(null);
    } else {
      setItems([
        ...items,
        {
          itemTitle: values.itemTitle,
          quantity: values.quantity,
          price: values.price,
          description: values.description,
        },
      ]);
    }

    setFieldValue("itemTitle", "");
    setFieldValue("quantity", 1);
    setFieldValue("price", "");
    setFieldValue("description", "");

    setStep(2);
  };

  const handleSubmit = (values, { setFieldValue }) => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      handleAddItem(values, setFieldValue);
    } else if (step === 2) {
      const data = {
        ...values,
        items,
        amount: totalSum,
        final_amount: totalSum,
        create_and_deploy: true,
        final_amount_card: totalSum,
        receive_in: values.receive_in,
      };

      setIsLoading(true);
      setIsCreating(true);

      CreateInvoiceAPI(data)
        .then((res) => {
          setIsLoading(false);
          setOpenLinkModal(true);
          setIsCreating(false);
          setInvoiceData(res.data.data.data);
          toast.success("Invoice created successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          const errorMessage = err.data.error.message;
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: "12px" },
          });
        });
    }
  };

  const handleEditItem = (index, setFieldValue) => {
    const item = items[index];
    setEditingIndex(index);
    setStep(1);
    setFieldValue("itemTitle", item.itemTitle);
    setFieldValue("quantity", item.quantity);
    setFieldValue("price", item.price);
    setFieldValue("description", item.description);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleIncrement = (setFieldValue, currentQuantity) => {
    setFieldValue("quantity", currentQuantity + 1);
  };

  const handleDecrement = (setFieldValue, currentQuantity) => {
    if (currentQuantity > 1) {
      setFieldValue("quantity", currentQuantity - 1);
    }
  };

  let totalSum = 0;
  if (items && items.length > 0) {
    totalSum = items.reduce(
      (acc, item) => acc + Number(item.quantity) * Number(item.price),
      0
    );
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-col justify-center align-items-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
          {isCreating && <label>Creating your invoice link</label>}
        </div>
      ) : (
        <>
          <div
            className="p-4 cursor-pointer"
            style={{ width: "20%" }}
            onClick={() =>
              step === 0 ? history.push("/invoice-widget") : setStep(step - 1)
            }
          >
            {BackSVG}
          </div>
          <PerfectScrollbar forceVisible="y" style={{ maxHeight: "92vh" }}>
            <div className="invoice-container">
              <Formik
                initialValues={{
                  title: "",
                  pay_fee: "you_pay",
                  itemTitle:
                    editingIndex !== null ? items[editingIndex].itemTitle : "",
                  quantity:
                    editingIndex !== null ? items[editingIndex].quantity : 1,
                  price: editingIndex !== null ? items[editingIndex].price : "",
                  description:
                    editingIndex !== null
                      ? items[editingIndex].description
                      : "",
                  notes: "",
                  receive_in: "crypto",
                }}
                validationSchema={
                  step === 0
                    ? initialValidationSchema
                    : step === 1
                    ? itemValidationSchema
                    : ""
                }
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <Form>
                      {step === 0 && (
                        <InvoiceTitle
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          beneficiaryData={beneficiaryData}
                          userData={userData}
                        />
                      )}

                      {step === 1 && (
                        <AddItems
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          editingIndex={editingIndex}
                          setFieldValue={setFieldValue}
                          handleIncrement={handleIncrement}
                          handleDecrement={handleDecrement}
                        />
                      )}

                      {step === 2 && (
                        <CreateInvoice
                          items={items}
                          values={values}
                          errors={errors}
                          setStep={setStep}
                          touched={touched}
                          userData={userData}
                          totalSum={totalSum}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          contractDetail={contractDetail}
                          handleEditItem={handleEditItem}
                          setEditingIndex={setEditingIndex}
                          handleRemoveItem={handleRemoveItem}
                        />
                      )}

                      {step === 2 && (
                        <div>
                          {items && items.length <= 0 ? (
                            <span
                              className="font-size-14 text-danger px-4 pb-4"
                              style={{
                                position: "fixed",
                                bottom: "5px",
                              }}
                            >
                              At least 1 item is required
                            </span>
                          ) : (
                            <div
                              className="flex justify-center p-4 button-div"
                              style={{
                                position: "fixed",
                                bottom: "5px",
                              }}
                            >
                              <button
                                type="submit"
                                className="button-style font-size-14"
                              >
                                Create Payment Link
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <Drawer
              open={openLinkModal}
              onClose={() => setOpenLinkModal(false)}
              direction="bottom"
              style={{
                zIndex: 10000,
                height: "80%",
                borderRadius: 10,
                background: "#F7F7F7",
              }}
            >
              <InvoiceLinkDrawer
                isFullInvoice={true}
                setItems={setItems}
                setStep={setStep}
                invoiceData={invoiceData}
                setOpenLinkModal={setOpenLinkModal}
              />
            </Drawer>
          </PerfectScrollbar>
        </>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default FullInvoice;
