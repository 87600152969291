import React, { useEffect, useState } from "react";
import { GetInvoiceDetailsAPI } from "../../services/invoice";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useHistory, useParams } from "react-router-dom";
import cardPayment from "../../images/credit-cards-payment.png";
import cryptoPayment from "../../images/money.png";
import { GoChevronRight } from "react-icons/go";
import bankSVG from "../../images/bank-svgrepo-com.svg";

function PaymentMethods() {
  const { invoice_code } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(invoice_code)
      .then((res) => {
        setIsLoading(false);
        setInvoiceDetail({
          ...res.data.data.data,
          industryType: res.data.data?.industryType,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    if (invoice_code) {
      getInvoiceDetails();
    } else {
      setErrorMessage("Invoice code is required");
    }
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          {errorMessage && errorMessage !== "" ? (
            <div
              className="flex flex-column justify-center"
              style={{ height: "100vh", alignItems: "center" }}
            >
              <span style={{ color: "#ff4560", fontSize: "20px" }}>
                {errorMessage}
              </span>
            </div>
          ) : (
            <>
              <SimpleBar forceVisible="y" style={{ maxHeight: "100vh" }}>
                <div
                  className="d-flex justify-content-center flex-col align-items-center"
                  style={{ marginTop: "15%" }}
                >
                  <span className="font-size-34 font-weight-600">
                    Pay {Number(invoiceDetail.amount).toFixed(2)}
                    <span style={{ color: "grey" }}> USD</span>
                  </span>
                  <span style={{ color: "grey" }} className="font-size-18">
                    to{" "}
                    <span className="text-black font-size-18">
                      {invoiceDetail?.industryType?.company_name}
                    </span>
                  </span>
                </div>
                <hr className="mx-4 my-4" />

                <span className="mx-4 font-size-18 font-weight-600">
                  How would you like to pay?
                </span>
                {invoiceDetail && invoiceDetail.receive_in === "crypto" && (
                  <div className="mx-4" style={{ marginTop: "5%" }}>
                    <div
                      className="d-flex flex-row align-items-center justify-content-between px-3 cursor-pointer"
                      style={{ background: "#EDEFF3", borderRadius: "8px" }}
                      onClick={() =>
                        history.push(`/card-payment/${invoice_code}`)
                      }
                    >
                      <div className="d-flex flex-row align-items-center">
                        <img
                          className="p-2 rounded"
                          src={cardPayment}
                          alt="cardPayment"
                          height={55}
                          width={55}
                        />
                        <div className="d-flex flex-col">
                          <span className="font-size-16 font-weight-500 mx-3">
                            Credit/Debit Card
                          </span>
                          <span className="font-size-12 font-weight-500 mx-3">
                            You pay {invoiceDetail.final_amount_card} USD
                          </span>
                        </div>
                      </div>
                      <div className="font-size-18">
                        <GoChevronRight />
                      </div>
                    </div>
                  </div>
                )}
                <div className="mx-4" style={{ marginTop: "5%" }}>
                  <div
                    className="d-flex flex-row align-items-center justify-content-between px-3 cursor-pointer"
                    style={{ background: "#EDEFF3", borderRadius: "8px" }}
                    onClick={() =>
                      history.push(`/bank-payment/${invoice_code}`)
                    }
                  >
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="p-2 rounded"
                        src={bankSVG}
                        alt="cardPayment"
                        height={55}
                        width={55}
                      />
                      <div className="d-flex flex-col">
                        <span className="font-size-16 font-weight-500 mx-3">
                          Bank Transfer
                        </span>
                        <span className="font-size-12 font-weight-500 mx-3">
                          You pay {invoiceDetail.final_amount_card} USD
                        </span>
                      </div>
                    </div>
                    <div className="font-size-18">
                      <GoChevronRight />
                    </div>
                  </div>
                </div>
                <div className="mx-4" style={{ marginTop: "5%" }}>
                  <div
                    className="d-flex flex-row align-items-center justify-content-between px-3 cursor-pointer"
                    style={{ background: "#EDEFF3", borderRadius: "8px" }}
                    onClick={() =>
                      history.push(`/crypto-payment/${invoice_code}`)
                    }
                  >
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="p-2 rounded"
                        src={cryptoPayment}
                        alt="cryptoPayment"
                        height={55}
                        width={55}
                      />
                      <div className="d-flex flex-col">
                        <span className="font-size-16 font-weight-500 mx-3">
                          Crypto Payment
                        </span>
                        <span className="font-size-12 font-weight-500 mx-3">
                          You pay {invoiceDetail.final_amount} USD
                        </span>
                      </div>
                    </div>
                    <div className="font-size-18">
                      <GoChevronRight />
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default PaymentMethods;
