import api from "../api/userApi";
import * as url from "../api/userURL";

export const GetPropertyAPI = (email) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.PROPERTY + `?email=${email}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddPropertyAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.PROPERTY, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
