import React from "react";
import "react-modern-drawer/dist/index.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BurgerIconSVG } from "../../App";

function SidebarMenu() {
  const history = useHistory();
  return (
    <>
      <span
        className="cursor-pointer"
        style={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          zIndex: 100000,
        }}
        onClick={() => {
          history.push("/settings");
        }}
      >
        {BurgerIconSVG}
      </span>
    </>
  );
}

export default SidebarMenu;
