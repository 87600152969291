import React from "react";

function PaymentWidget() {
  let params = window.location.search;

  return (
    <iframe
      style={{ border: "1px solid black", borderRadius: "10px" }}
      title="Buy or sell"
      loading="lazy"
      allow="accelerometer; autoplay; camera; gyroscope; payment"
      className="widget-container"
      src={`/pay/${params}`}
      frameBorder="0"
    ></iframe>
  );
}

export default PaymentWidget;
