import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleIcon from "@mui/icons-material/Article";
import LoginIcon from "@mui/icons-material/Login";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

function Header({ funMode, setFunMode }) {
  const [top, setTop] = useState(true);
  const [openBurger, setOpenBurger] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const handleBurgerClick = () => {
    setOpenBurger(!openBurger);
  };

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setOpenBurger(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Logo />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            to="https://rampnalysis.gitbook.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>

            <span style={{ fontSize: "16px" }}>Docs</span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            to="https://app.rampnalysis.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <span style={{ fontSize: "16px" }}>SignIn</span>{" "}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            to="https://app.rampnalysis.com/register?account_name=business"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <AdsClickIcon />
            </ListItemIcon>
            <span style={{ fontSize: "16px" }}>Get your widget</span>{" "}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <header
      className={`fixed w-full header-bottom z-50 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white blur shadow-lg"
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between height-64px">
          <div className="flex-shrink-0 mr-4 mt-3">
            <Link to="/" className="block" aria-label="Cruip">
              <Logo />
            </Link>
          </div>

          {!isMobileView ? (
            <nav className="flex flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <FormGroup className="d-flex align-items-start">
                    <FormControlLabel
                      style={{ margin: "0px" }}
                      label={
                        <div>
                          <span
                            className="ml-2 mt-1"
                            style={{ fontSize: "12px" }}
                          >
                            Fun Mode
                          </span>
                        </div>
                      }
                      labelPlacement="start"
                      control={
                        <Switch
                          color="warning"
                          style={{ color: "greenyellow" }}
                          checked={funMode}
                          onChange={() => setFunMode(!funMode)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </FormGroup>
                </li>
                <li>
                  <a
                    href="https://rampnalysis.gitbook.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 ml-3"
                  >
                    Docs
                  </a>
                </li>
                <li>
                  <a
                    href="https://app.rampnalysis.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 ml-3"
                  >
                    Sign in
                  </a>
                </li>
                <li>
                  <a
                    href="https://app-dev.rampnalysis.com/register?account_name=business"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-sm text-gray-200 bg-gray-900 ml-3"
                  >
                    Get your widget
                    <svg
                      className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          ) : (
            <>
              <nav className="flex flex-grow">
                <ul className="flex flex-grow justify-end flex-wrap items-center">
                  <li>
                    <FormGroup className="d-flex align-items-start">
                      <FormControlLabel
                        style={{ margin: "0px" }}
                        label={
                          <div>
                            <span
                              className="ml-2 mt-1"
                              style={{ fontSize: "12px" }}
                            >
                              Fun Mode
                            </span>
                          </div>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            color="warning"
                            style={{ color: "greenyellow" }}
                            checked={funMode}
                            onChange={() => setFunMode(!funMode)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </FormGroup>
                  </li>
                </ul>
              </nav>
              <label
                className={`burger ${openBurger ? "open" : ""}`}
                htmlFor="burger"
                onClick={(e) => {
                  e.preventDefault();
                  handleBurgerClick();
                }}
              >
                <input
                  type="checkbox"
                  id="burger"
                  checked={openBurger}
                  onChange={() => {}}
                />
                <span></span>
                <span></span>
                <span></span>
              </label>
              <Drawer
                anchor="right"
                open={openBurger}
                style={{ minWidth: "260px", padding: "10px " }}
                onClose={() => setOpenBurger(false)}
              >
                {DrawerList}
              </Drawer>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
