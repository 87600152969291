import React, { useState } from "react";
import { CloseSVG, CopySVG, MailSVG, QRCodeSVG, ShareSVG } from "../../App";
import { useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import QRCode from "react-qr-code";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LineShareButton,
  LineIcon,
} from "react-share";

function InvoiceLinkDrawer({
  invoiceData,
  setOpenLinkModal,
  isFullInvoice = false,
  setItems = () => {},
  setStep = () => {},
}) {
  const history = useHistory();
  const [isLoading] = useState(false);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-col justify-center align-items-center"
          style={{ height: "80vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div>
          <PerfectScrollbar style={{ maxHeight: "60vh" }}>
            <div className="mt-5 mb-3">
              <div className="page-title mt-4 p-4 flex align-items-center justify-center">
                <div
                  className="absolute cursor-pointer"
                  style={{ left: "20px" }}
                  onClick={() => {
                    if (isFullInvoice) {
                      setItems([]);
                      setStep(0);
                      setOpenLinkModal(false);
                    } else {
                      setOpenLinkModal(false);
                    }
                  }}
                >
                  {CloseSVG}
                </div>
                <span className="font-size-20">Link is ready to share</span>
              </div>
              <ShareInvoiceFunc invoiceData={invoiceData} />
            </div>
          </PerfectScrollbar>
          <div
            className="flex justify-center flex-col p-4 button-div"
            style={{
              position: "fixed",
              bottom: "65px",
            }}
            onClick={() => {
              if (isFullInvoice) {
                setItems([]);
                setStep(0);
                setOpenLinkModal(false);
              } else {
                setOpenLinkModal(false);
              }
            }}
          >
            <button id="login-button" type="submit" className="button-style">
              <div className="font-size-14">New Payment Link</div>
            </button>
          </div>
          <div
            className="flex justify-center flex-col p-4 button-div"
            style={{
              position: "fixed",
              bottom: "5px",
            }}
          >
            <button
              style={{
                background: "#fff",
                color: "black",
                border: "1px solid black",
              }}
              id="login-button"
              type="submit"
              className="button-style mt-3"
              onClick={() => history.push("/invoice-widget")}
            >
              <div className="font-size-14">Done</div>
            </button>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default InvoiceLinkDrawer;

export const ShareInvoiceFunc = ({ invoiceData, showLink = true }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const handleSendEmail = () => {
    const subject = "Invoice Payment Link";
    const body = `Hello,\n\nPlease use the following link to pay your invoice:\nhttps://1buy.io/pay?invoice_code=${invoiceData.short_code}\n\nThank you.`;
    window.location.href = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };
  const invoiceLink = `https://1buy.io/pay/${invoiceData.short_code}`;

  return (
    <div className="flex flex-col p-4">
      {showLink && (
        <>
          <label className="font-size-16 text-black">
            https://1buy.io/pay/{invoiceData.short_code}
          </label>
          <label className="font-size-14">
            Customer can pay you securely through the link.
          </label>
        </>
      )}
      <button
        className="mt-2 button-transparent flex align-items-center px-3 mt-4"
        style={{ border: "0px" }}
        onClick={handleSendEmail}
      >
        <div>{MailSVG}</div>
        <div className="pl-3">Send via email</div>
      </button>
      <button
        className="mt-2 button-transparent flex align-items-center px-3"
        style={{ border: "0px" }}
        onClick={() => setShowShareOptions(!showShareOptions)}
      >
        <div>{ShareSVG}</div>
        <div className="pl-3">
          {showShareOptions ? "Hide Share Options" : "Share"}
        </div>
      </button>
      {showShareOptions && (
        <div className="flex mt-3 justify-around">
          <WhatsappShareButton
            url={invoiceLink}
            title={"Pay your invoice securely"}
          >
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>

          <FacebookMessengerShareButton
            url={invoiceLink}
            quote={"Pay your invoice securely"}
          >
            <FacebookMessengerIcon size={40} round />
          </FacebookMessengerShareButton>

          <TelegramShareButton
            url={invoiceLink}
            title={"Pay your invoice securely"}
          >
            <TelegramIcon size={40} round />
          </TelegramShareButton>

          <LineShareButton url={invoiceLink}>
            <LineIcon size={40} round />
          </LineShareButton>
        </div>
      )}
      <button
        className="mt-2 button-transparent flex align-items-center px-3"
        style={{ border: "0px" }}
        onClick={() => setShowQRCode(!showQRCode)}
      >
        <div>{QRCodeSVG}</div>
        <div className="pl-3">
          {showQRCode ? "Hide QR code" : "Show QR code"}
        </div>
      </button>
      {showQRCode && (
        <div className="mt-4 flex justify-center flex-col align-items-center">
          <span className="mb-3 text-black font-size-16">
            Scan QR for payment
          </span>
          <QRCode value={invoiceLink} size={200} />
        </div>
      )}
      <button
        className="mt-2 button-transparent flex align-items-center px-3"
        style={{ border: "0px" }}
        type="button"
        onClick={() => {
          copy(invoiceLink);
          toast.success("Invoice payment link copied successfully", {
            style: { fontSize: "12px" },
          });
        }}
      >
        <div>{CopySVG}</div>
        <div className="pl-3">Copy Link</div>
      </button>
    </div>
  );
};
