import React from "react";

function Complete({ t, setStep }) {
  return (
    <div className="search-box glass-effect py-4">
      <span className="font-size-22">{t("complete.thank_you")}</span>
      <span>{t("complete.follow_up")}</span>
      <button
        type="button"
        className="search-button"
        onClick={() => setStep(0)}
      >
        {t("complete.resubmit")}
      </button>
    </div>
  );
}

export default Complete;
