import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./Property.css";
import { FaArrowLeft, FaHome, FaLocationArrow } from "react-icons/fa";
import { Range } from "react-range";
import Select from "react-select";

const property = [
  { label: "Anywhere", value: "anywhere" },
  { label: "Dubai Marina", value: "dubai_marina" },
  { label: "Palm Jumeirah", value: "palm_jumeirah" },
  { label: "Downtown Dubai", value: "downtown_dubai" },
  { label: "Jumeirah Village Circle (JVC)", value: "jumeirah_village_circle" },
  { label: "Business Bay", value: "business_bay" },
  { label: "Arabian Ranches", value: "arabian_ranches" },
  { label: "Jumeirah Lake Towers (JLT)", value: "jumeirah_lake_towers" },
  { label: "Dubai Hills Estate", value: "dubai_hills_estate" },
  { label: "Mirdif", value: "mirdif" },
  { label: "Al Barsha", value: "al_barsha" },
  { label: "The Greens", value: "the_greens" },
  { label: "The Meadows", value: "the_meadows" },
  { label: "The Springs", value: "the_springs" },
  { label: "Jumeirah Islands", value: "jumeirah_islands" },
  { label: "Dubai Silicon Oasis", value: "dubai_silicon_oasis" },
  { label: "Jumeirah Golf Estates", value: "jumeirah_golf_estates" },
  { label: "City Walk", value: "city_walk" },
  { label: "Dubai Sports City", value: "dubai_sports_city" },
  { label: "Emirates Hills", value: "emirates_hills" },
  { label: "Motor City", value: "motor_city" },
  { label: "Al Furjan", value: "al_furjan" },
  { label: "Green Community", value: "green_community" },
  { label: "Al Wasl", value: "al_wasl" },
  { label: "The Lakes", value: "the_lakes" },
  { label: "Dubai Festival City", value: "dubai_festival_city" },
  { label: "Al Qusais", value: "al_qusais" },
  { label: "International City", value: "international_city" },
  { label: "Dubai South", value: "dubai_south" },
  { label: "Bluewaters Island", value: "bluewaters_island" },
  { label: "Madinat Jumeirah Living", value: "madinat_jumeirah_living" },
  { label: "Port de La Mer", value: "port_de_la_mer" },
  { label: "DAMAC Hills", value: "damac_hills" },
  { label: "Al Warqa", value: "al_warqa" },
  { label: "Jebel Ali", value: "jebel_ali" },
  { label: "Al Safa", value: "al_safa" },
  { label: "Serenia Residences", value: "serenia_residences" },
  { label: "Tilal Al Ghaf", value: "tilal_al_ghaf" },
  { label: "Mudon", value: "mudon" },
  { label: "Deira", value: "deira" },
  { label: "Al Rashidiya", value: "al_rashidiya" },
];

function InvestmentPreferences({
  t,
  handleSubmit,
  setStep,
  email,
  defaultValues,
  shakeIt,
}) {
  const [rangeValues, setRangeValues] = React.useState([
    defaultValues?.budgetRangeMin || 500000,
    defaultValues?.budgetRangeMax || 30000000,
  ]);

  const propertyTypeOptions = [
    { value: "villas", label: "Villas" },
    { value: "apartments", label: "Apartments" },
    { value: "penthouses", label: "Penthouses" },
    { value: "studios", label: "Studios" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      minHeight: "auto",
      "&:hover": {
        border: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        color: "black",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      border: "1px solid #ccc",
      marginLeft: "-40px",
      width: "calc(80%)",
    }),

    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.1)" : "transparent",
      ":active": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    }),
  };

  const investmentSubmit = (values) => {
    const data = {
      propertyType: values.propertyType.map((option) => option.value),
      preferredLocation: values.preferredLocation.map((option) => option.value),
      budgetRangeMin: rangeValues[0],
      budgetRangeMax: rangeValues[1],
      email,
    };
    handleSubmit(data);
    setStep(3);
  };

  return (
    <Formik
      initialValues={{
        propertyType: defaultValues?.propertyType
          ? propertyTypeOptions.filter((option) =>
              defaultValues.propertyType.includes(option.value)
            )
          : [],
        preferredLocation: defaultValues?.preferredLocation
          ? property.filter((option) =>
              defaultValues.preferredLocation.includes(option.value)
            )
          : [],
      }}
      validationSchema={Yup.object({
        propertyType: Yup.array()
          .min(1, t("investment_preferences.property_type_required"))
          .required(t("investment_preferences.property_type_required")),
        preferredLocation: Yup.array()
          .min(1, t("investment_preferences.location_required"))
          .required(t("investment_preferences.location_required")),
      })}
      onSubmit={investmentSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <div className="cursor-pointer" onClick={() => setStep(1)}>
            <FaArrowLeft />
          </div>
          <span className="font-size-18 font-weight-500">
            {t("investment_preferences.title")}
          </span>

          <div className="search-field input-icon-wrapper">
            <FaHome className="input-icon" />
            <Select
              id="propertyType"
              name="propertyType"
              options={propertyTypeOptions}
              isMulti
              classNamePrefix="react-select"
              className="input-field-pr"
              value={values.propertyType}
              onChange={(selectedOptions) => {
                setFieldValue("propertyType", selectedOptions);
              }}
              onBlur={handleBlur}
              placeholder={t("investment_preferences.property_type")}
              styles={customStyles}
            />
          </div>
          {touched.propertyType && errors.propertyType && (
            <div className="error-message">{errors.propertyType}</div>
          )}

          <div className="search-field">
            <label
              htmlFor="budgetRange"
              className="font-size-14 text-white font-weight-500 mb-2"
            >
              {t("investment_preferences.budget_range")}
            </label>
            <Range
              step={50000}
              min={250000}
              max={50000000}
              values={rangeValues}
              onChange={(values) => setRangeValues(values)}
              ariaLabel={[
                t("form.investment_preferences.budget_min"),
                t("form.investment_preferences.budget_max"),
              ]}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "97%",
                    marginTop: "10px",
                    marginLeft: "7px",
                    backgroundColor: "#ccc",
                    marginBottom: "10px",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#000",
                    borderRadius: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-28px",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                      padding: "4px",
                      borderRadius: "4px",
                      backgroundColor: "#000",
                    }}
                  >
                    ${rangeValues[index].toLocaleString()}
                  </div>
                </div>
              )}
            />
            <label className="font-size-14 text-white">
              {t("investment_preferences.budget_display")} $
              {rangeValues[0].toLocaleString()} - $
              {rangeValues[1].toLocaleString()}
            </label>
          </div>

          <div className="search-field input-icon-wrapper">
            <FaLocationArrow className="input-icon" />
            <Select
              id="preferredLocation"
              name="preferredLocation"
              options={property}
              isMulti
              classNamePrefix="react-select"
              className="input-field-pr"
              value={values.preferredLocation}
              onChange={(selectedOptions) => {
                setFieldValue("preferredLocation", selectedOptions);
              }}
              onBlur={handleBlur}
              placeholder={t("investment_preferences.preferred_location")}
              styles={customStyles}
            />
          </div>
          {touched.preferredLocation && errors.preferredLocation && (
            <div className="error-message">{errors.preferredLocation}</div>
          )}

          <button type="submit" className="search-button">
            {t("investment_preferences.continue")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default InvestmentPreferences;
