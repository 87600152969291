import React, { useEffect, useState } from "react";
import { createParamsData } from "../params";
import {
  GetInvoiceDetailsAPI,
  getInvoiceSmartContractAPI,
  GetMyProfileAPI,
} from "../../services/invoice";
import { BackSVG } from "../../App";
import { useHistory } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ShareInvoiceFunc } from "./InvoiceLinkDrawer";
import { Toaster } from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function InvoiceDetail() {
  let params = window.location.search;
  let paramsValue = createParamsData(params);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState({});
  const [contractDetail, setContractDetail] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(paramsValue["invoice_code"])
      .then((res) => {
        setIsLoading(false);
        setInvoiceDetail({
          ...res.data.data.data,
          industryType: res.data.data?.industryType,
        });
        // if (res.data.data.data && res.data.data.data.items.length > 0) {
        //   totalSum = res.data.data.data.items.reduce(
        //     (acc, item) => acc + Number(item.quantity) * Number(item.price),
        //     0
        //   );
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };
  useEffect(() => {
    if (paramsValue["invoice_code"]) {
      getInvoiceDetails();
    }
  }, []);

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        setUserData(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getContractDetail = () => {
    setIsLoading(true);
    getInvoiceSmartContractAPI()
      .then((res) => {
        const data = res.data.data.data;
        setContractDetail(data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getContractDetail();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          {errorMessage && errorMessage !== "" ? (
            <div
              className="flex flex-column justify-center"
              style={{ height: "100vh", alignItems: "center" }}
            >
              <span style={{ color: "#ff4560", fontSize: "20px" }}>
                {errorMessage}
              </span>
            </div>
          ) : (
            <>
              <div
                className="p-4 d-flex flex-row align-items-center justify-content-between"
                style={{ background: "#F7F7F7" }}
              >
                <div
                  className="cursor-pointer "
                  onClick={() => history.push("/invoices")}
                >
                  {BackSVG}
                </div>
                <span className="d-flex justify-content-center align-items-center mx-auto font-size-18 font-weight-600">
                  Invoice
                </span>
              </div>
              <PerfectScrollbar
                style={{
                  maxHeight: "calc(100vh - 70px)",
                  overflowX: "hidden",
                }}
              >
                <div
                  className="mx-4 p-3 d-flex justify-content-between align-items-center"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "16px",
                    marginTop: "5%",
                    height: "55px",
                  }}
                >
                  <span className="font-size-14">
                    Invoice no. #{invoiceDetail.short_code}
                  </span>
                  <div className="button-group">
                    {!invoiceDetail.is_paid ? (
                      <button
                        className={`font-size-14 font-weight-500`}
                        style={{
                          background: "#FFC694",
                          border: "0px",
                          color: "#000",
                        }}
                      >
                        Pending
                      </button>
                    ) : (
                      <span
                        className="ml-3 px-2 py-1 text-xs font-weight-500 rounded-full"
                        style={{ background: "#BEFF7D" }}
                      >
                        Complete
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="mx-4 p-3"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "18px",
                    marginTop: "5%",
                  }}
                >
                  <span
                    className="font-size-14 px-2 font-weight-500"
                    style={{ color: "#494949" }}
                  >
                    Client
                  </span>
                  <div className="d-flex flex-row align-items-center justify-content-between cursor-pointer">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="p-2 inline-block rounded-full"
                        src={
                          userData.profile_image
                            ? userData.profile_image
                            : "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png"
                        }
                        alt="cardPayment"
                        style={{ width: "55px", height: "55px" }}
                        height={55}
                        width={55}
                      />
                      <div className="d-flex flex-col">
                        <span className="font-size-16 font-weight-500 mx-3">
                          {userData.company_name}
                        </span>
                        <span
                          className="font-size-12 font-weight-500 mx-3"
                          style={{ color: "#494949" }}
                        >
                          {userData.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {invoiceDetail &&
                  invoiceDetail.items &&
                  invoiceDetail.items.length > 0 && (
                    <div
                      className="mx-4 p-3"
                      style={{
                        background: "#EDEFF3",
                        borderRadius: "18px",
                        marginTop: "5%",
                      }}
                    >
                      <div>
                        <label htmlFor="title" className="font-size-16 px-2">
                          Item
                        </label>
                      </div>
                      <div className="mt-1">
                        {invoiceDetail.items &&
                          invoiceDetail.items.length > 0 &&
                          invoiceDetail.items.map((item, index) => (
                            <>
                              <div className="d-flex flex-row align-items-center justify-content-between cursor-pointer">
                                <div className="d-flex flex-row align-items-center">
                                  <div className="d-flex flex-col">
                                    <span
                                      className="font-size-16 font-weight-500 mx-2"
                                      style={{ lineHeight: "24px" }}
                                    >
                                      {item.itemTitle}
                                    </span>
                                    <span
                                      className="font-size-12 mx-2"
                                      style={{ color: "#6F6F6F" }}
                                    >
                                      QTY {item.quantity} X $ {item.price}
                                    </span>
                                  </div>
                                </div>
                                <span className="mr-2 mt-3">
                                  ${Number(item.quantity) * Number(item.price)}{" "}
                                </span>
                              </div>
                              {invoiceDetail.items.length - 1 !== index && (
                                <hr className="mt-3 mb-3" />
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  )}
                <div
                  className="mx-4"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "18px",
                    marginTop: "5%",
                  }}
                >
                  <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      Sub total
                    </label>
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      $ {invoiceDetail.amount}
                    </label>
                  </div>
                  {invoiceDetail.pay_fee === "you_pay" ? (
                    <>
                      <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          Fee Range
                        </label>
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          {100 - Number(contractDetail?.business_percentage)}%
                          to{" "}
                          {100 -
                            Number(contractDetail?.business_percentage_card)}
                          %
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          Bank Final Total (
                          {100 - Number(contractDetail?.business_percentage)}
                          %)
                        </label>
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          ${" "}
                          {Number(invoiceDetail.amount) +
                            (invoiceDetail.amount *
                              (100 -
                                Number(contractDetail?.business_percentage))) /
                              100}
                        </label>
                      </div>
                      <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          Card Final Total (
                          {100 -
                            Number(contractDetail?.business_percentage_card)}
                          %)
                        </label>
                        <label
                          htmlFor="title"
                          className="font-size-14 label-color font-weight-500 mx-2"
                        >
                          ${" "}
                          {Number(invoiceDetail.amount) +
                            (invoiceDetail.amount *
                              (100 -
                                Number(
                                  contractDetail?.business_percentage_card
                                ))) /
                              100}
                        </label>
                      </div>
                    </>
                  )}

                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderBottomLeftRadius: "18px",
                      borderBottomRightRadius: "18px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={toggle}
                  >
                    {invoiceDetail.pay_fee === "you_pay" ? (
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="px-3 font-size-16">
                            What you get breakdown
                          </span>
                          <div className="mx-2">
                            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                          </div>
                        </div>
                        <div
                          className={`collapsible-content ${
                            isOpen ? "open" : "collapsed"
                          }`}
                        >
                          <div className="d-flex justify-content-between mt-3">
                            <span className="px-3 font-size-16">
                              Bank Payment (
                              {100 -
                                Number(contractDetail?.business_percentage)}
                              %)
                            </span>
                            <span className="px-3 font-size-16">
                              ${" "}
                              {Number(invoiceDetail.amount) -
                                (invoiceDetail.amount *
                                  (100 -
                                    Number(
                                      contractDetail?.business_percentage
                                    ))) /
                                  100}{" "}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="px-3 font-size-16">
                              Card Payment (
                              {100 -
                                Number(
                                  contractDetail?.business_percentage_card
                                )}
                              %)
                            </span>
                            <span className="px-3 font-size-16">
                              ${" "}
                              {Number(invoiceDetail.amount) -
                                (invoiceDetail.amount *
                                  (100 -
                                    Number(
                                      contractDetail?.business_percentage_card
                                    ))) /
                                  100}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <span className="px-3 font-size-16">You Get</span>
                        <span className="px-3 font-size-16">
                          $ {invoiceDetail.amount}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mx-1">
                  <ShareInvoiceFunc
                    invoiceData={invoiceDetail}
                    showLink={false}
                  />
                </div>
              </PerfectScrollbar>
            </>
          )}
        </>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default InvoiceDetail;
