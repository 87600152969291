import React from "react";

function AddItems({
  values,
  errors,
  touched,
  handleChange,
  editingIndex,
  setFieldValue,
  handleIncrement,
  handleDecrement,
}) {
  return (
    <div>
      {" "}
      <span className="text-left font-size-32 page-title mt-4 pt-4 px-4">
        {editingIndex !== null ? "Edit Item" : "Add Items"}
      </span>
      <div className="mb-1 flex flex-col px-4 pt-4">
        <label
          htmlFor="itemTitle"
          className="font-size-14 label-color ml-1 mb-1"
        >
          Title
        </label>
        <input
          type="text"
          value={values.itemTitle}
          placeholder="Enter itemTitle"
          onChange={handleChange}
          name="itemTitle"
          className={`input-field font-size-14 ${
            errors.itemTitle && touched.itemTitle ? "error-border" : ""
          }`}
        />
        {!values.itemTitle && errors.itemTitle && touched.itemTitle && (
          <div className="text-left mt-1 ml-1 text-danger font-size-14">
            {errors.itemTitle}
          </div>
        )}
      </div>
      <div className="mb-1 flex flex-row justify-between px-4 pt-4">
        <div className="flex flex-col mr-2" style={{ width: "48%" }}>
          <label
            htmlFor="quantity"
            className="font-size-14 label-color ml-1 mb-1"
          >
            Quantity
          </label>
          <div className="flex justify-between input-field px-4">
            <button
              type="button"
              onClick={() => handleDecrement(setFieldValue, values.quantity)}
              className="px-2 font-size-32"
            >
              -
            </button>
            <input
              type="text"
              value={values.quantity}
              onChange={handleChange}
              name="quantity"
              readOnly
              className="input-field text-center w-12"
            />
            <button
              type="button"
              onClick={() => handleIncrement(setFieldValue, values.quantity)}
              className="px-2 font-size-32"
            >
              +
            </button>
          </div>
          {errors.quantity && touched.quantity && (
            <div className="text-left mt-1 ml-1 text-danger font-size-14">
              {errors.quantity}
            </div>
          )}
        </div>
        <div className="flex flex-col ml-2" style={{ width: "48%" }}>
          <label htmlFor="price" className="font-size-14 label-color ml-1 mb-1">
            Price ($)
          </label>
          <input
            type="number"
            value={values.price}
            placeholder="Enter price"
            onChange={handleChange}
            name="price"
            className={`input-field font-size-14 ${
              errors.price && touched.price ? "error-border" : ""
            }`}
          />
          {!values.price && errors.price && touched.price && (
            <div className="text-left mt-1 ml-1 text-danger font-size-14">
              {errors.price}
            </div>
          )}
        </div>
      </div>
      <div className="mb-1 flex flex-col px-4 pt-4">
        <label
          htmlFor="description"
          className="font-size-14 ml-1 mb-1 label-color"
        >
          Notes
        </label>
        <textarea
          value={values.description}
          placeholder="Write notes..."
          onChange={handleChange}
          name="description"
          style={{ minHeight: "141px" }}
          className={`input-field font-size-14 py-1 ${
            errors.description && touched.description ? "error-border" : ""
          }`}
        />
        {errors.description && touched.description && (
          <div className="text-left mt-1 ml-1 text-danger font-size-14">
            {errors.description}
          </div>
        )}
      </div>
      <div
        className="flex justify-center p-4 button-div"
        style={{ position: "fixed", bottom: "5px" }}
      >
        <button type="submit" className="button-style font-size-14">
          {editingIndex !== null ? "Update Item" : "Add Item"}
        </button>
      </div>
    </div>
  );
}

export default AddItems;
