import React, { useEffect, useState } from "react";
import { createParamsData } from "../params";
import { GetInvoiceDetailsAPI } from "../../services/invoice";
import { useParams } from "react-router-dom";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import WertModule from "@wert-io/module-react-component";
import { BackSVG } from "../../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CardPayment() {
  const { invoice_code } = useParams();
  let params = window.location.search;
  let paramsValue = createParamsData(params);
  const history = useHistory();
  const [optionsData, setOptionsData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const privateKey = process.env.REACT_APP_WERT_PRIVATE_KEY;

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(invoice_code)
      .then((res) => {
        setIsLoading(false);
        const company = res.data.data.industryType;

        if (paramsValue["is_dev"] === "yes") {
          const scOptions = {
            address: "0x079fEaB5EF58A0a82425FC3eeCEF9dAADd13e822",
            commodity: "TT",
            commodity_amount: Number(res.data.data.data.final_amount_card),
            network: "amoy",
            sc_address: res.data.data.sc_address,
            sc_input_data: res.data.data.sc_input_data,
          };
          const signedData = signSmartContractData(
            scOptions,
            "0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3"
          );

          const options = {
            ...scOptions,
            partner_id: "01HE2KXEGTAT7XDRGFW3WSQQAR",
            origin: "https://sandbox.wert.io",
            is_crypto_hidden: true,
            extra: {
              item_info: {
                author: company.company_name,
                author_image_url:
                  "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png",
                image_url:
                  "https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/95069-1729485115566.png",
                name: paramsValue["name"]
                  ? paramsValue["name"]
                  : company.company_name,
                category: paramsValue["name"]
                  ? paramsValue["name"]
                  : company.company_name,
              },
            },
            ...signedData,
          };
          setOptionsData(options);
        } else {
          const scOptions = {
            address: "0x079fEaB5EF58A0a82425FC3eeCEF9dAADd13e822",
            commodity: "USDCC",
            commodity_amount:
              res.data.data.data.pay_fee === "you_pay"
                ? Number(res.data.data.data.final_amount)
                : Number(res.data.data.data.final_amount_card),
            network: process.env.REACT_APP_WERT_NETWORK_INVOICE || "polygon",
            sc_address: res.data.data.sc_address,
            sc_input_data: res.data.data.sc_input_data,
          };

          const signedData = signSmartContractData(
            scOptions,
            privateKey ||
              "0x5a1815314f31a8bfc466589cfca7aa4387b01ff932c5ab4130fd85845fe6379a"
          );

          const options = {
            ...scOptions,
            partner_id:
              process.env.REACT_APP_WERT_PARTNER_ID ||
              "01HZPBD4HKDT49MDQ8VTBGHXFA",
            origin:
              process.env.REACT_APP_WERT_ORIGIN || "https://widget.wert.io",
            is_crypto_hidden: true,
            extra: {
              item_info: {
                author: company.company_name,
                author_image_url: company.profile_image
                  ? company.profile_image
                  : "https://rampnalysisdocs.s3.amazonaws.com/69469-1724945704942.png",
                image_url:
                  "https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/95069-1729485115566.png",
                name: paramsValue["name"]
                  ? paramsValue["name"]
                  : res.data.data.data.title,
                category: paramsValue["name"]
                  ? paramsValue["name"]
                  : company.company_name,
              },
            },
            ...signedData,
          };
          setOptionsData(options);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    if (invoice_code) {
      getInvoiceDetails();
    } else {
      setErrorMessage("Invoice code is required");
    }
  }, []);

  return (
    <div className="centered-container">
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div className="d-flex flex-col">
          {errorMessage && <span>{errorMessage}</span>}
          <div
            className="p-4 d-flex flex-row align-items-center justify-content-between"
            style={{ background: "#F7F7F7" }}
          >
            <div
              className="cursor-pointer"
              onClick={() => history.push(`/pay/${invoice_code}`)}
            >
              {BackSVG}
            </div>
            <span className="d-flex justify-content-center align-items-center mx-auto font-size-18 font-weight-600">
              Card Payment
            </span>
          </div>

          {optionsData && Object.keys(optionsData).length > 0 && (
            <WertModule
              options={{
                ...optionsData,
                click_id: JSON.stringify({ invoice_code }),
                listeners: {
                  position: (data) => console.log("step:", data.step),
                  "payment-status": (data) => {
                    if (
                      (data.status === "pending" ||
                        data.status === "order_complete") &&
                      data.tx_id
                    ) {
                      history.push(`/pay/${invoice_code}`);
                    }
                  },
                },
              }}
              className="wert-container"
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CardPayment;
